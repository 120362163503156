import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { TariffUpdateModalComponent } from './tariff-update-modal.component';

@NgModule({
  imports: [
    IonicModule,
    CommonModule
  ],
  declarations: [TariffUpdateModalComponent],
  exports: [TariffUpdateModalComponent],
  entryComponents: [TariffUpdateModalComponent]
})
export class TariffUpdateModalComponentModule { }
