import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {

  constructor() { }

  public load(gtmCode: string): boolean {
    if (!gtmCode) {
      console.warn(`Se intentó cargar GTM sin código.`);
      return false;
    }
    else {
      // añadimos el GTM en el <head>.
      try {
        window['dataLayer'] = window['dataLayer'] || []; window['dataLayer'].push({
          'gtm.start':
            new Date().getTime(), event: 'gtm.js'
        });

        var f = document.getElementsByTagName('script')[0],
          j = document.createElement('script'),
          dl = 'dataLayer' != 'dataLayer' ? '&l=' + 'dataLayer' : ''; j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + gtmCode + dl; f.parentNode.insertBefore(j, f);
        return true;
      } catch (e) {
        console.warn('No se pudo cargar GTM', e);
        return false;
      }
    }
  }
}
