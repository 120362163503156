import {ErrorHandler, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { registerLocaleData, APP_BASE_HREF } from '@angular/common';
import localeCo from '@angular/common/locales/es-CO';

// reCaptcha
import { RECAPTCHA_V3_SITE_KEY, RECAPTCHA_LANGUAGE, RecaptchaV3Module } from 'ng-recaptcha';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// interceptor para añadir bearer
import { TokenInterceptor } from './services/token.interceptor';

// custom modules
import { MessageModalComponentModule } from './components/message-modal/message-modal.component.module';
import { TariffUpdateModalComponentModule } from './components/tariff-update-modal/tariff-update-modal.component.module';
import { TycModalComponentModule } from './components/tyc-modal/tyc-modal.component.module';
import { ClickToCallModalComponentModule } from './components/click-to-call-modal/click-to-call-modal.component.module';
import { SuccessClickToCallModalComponentModule } from './components/success-click-to-call-modal/success-click-to-call-modal.component.module';
import { PromotionsModalComponentModule } from './components/promotions-modal/promotions-modal.component.module';

// i18n
registerLocaleData(localeCo, 'es-CO');

// 3rd party
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

// environment
import { CONFIG } from 'src/environments/environment';
import {GlobalErrorHandlerService} from "./services/global-error-handler.service";
import { OnlyAlphanumericDirective } from './directives/only-alphanumeric.directive';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MessageModalComponentModule,
    TariffUpdateModalComponentModule,
    TycModalComponentModule,
    ClickToCallModalComponentModule,
    SuccessClickToCallModalComponentModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    PromotionsModalComponentModule,
    IonicModule.forRoot({
      mode: 'md'
    }),
    AppRoutingModule,
    HttpClientModule,
    RecaptchaV3Module
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: CONFIG.GENERAL.RECAPTCHA_KEY },
    { provide: RECAPTCHA_LANGUAGE, useValue: 'es-419' },
    { provide: APP_BASE_HREF, useValue: window['base-href']},
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
