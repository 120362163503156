import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';

import { ErrorReporterService } from "./error-reporter.service";

import { PortalConfigService } from './portal-config.service';

import { Observable } from 'rxjs';
import { tap } from "rxjs/operators";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(
        public portalConfig: PortalConfigService,
        private errorReporterService: ErrorReporterService,
    ) { }

    addToken(request: HttpRequest<any>, next: HttpHandler): HttpRequest<any> {
        const hash = this.portalConfig.getHash();
        if (hash) {
            request = request.clone({
                setHeaders: {
                    Authorization: `bearer ${hash}`
                }
            });
        }
        return request;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(this.addToken(request, next)).pipe(
            tap((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        if (typeof event.body.Success !== 'undefined' && event.body.Success === false) {
                            this.errorReporterService.handleHttpSuccessFalse(event);
                        }
                        // do stuff with response if needed
                    }
                }, (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        // si es error para registrar el error, no lo mandamos, sino loop infinito.
                        if (err.url.indexOf('LogRegister') === -1) {
                            this.errorReporterService.handleHttpErrorResponse(err);
                        }
                    }
                }
            ));
    }
}