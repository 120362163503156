import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-tariff-update-modal',
  templateUrl: './tariff-update-modal.component.html',
  styleUrls: ['./tariff-update-modal.component.scss'],
})
export class TariffUpdateModalComponent implements OnInit {
  // viejo valor de la tarifa
  @Input('oldValue') oldValue: string;

  // valor actualiza de la tarifa
  @Input('newValue') newValue: string;

  @Input('plate') plate: string;

  constructor(
    private _modalController: ModalController,
  ) { }

  ngOnInit() {}

  public close(): void {
    this._modalController.dismiss();
  }

}
