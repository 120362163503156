import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from "@angular/common";

// reCaptcha
import { ReCaptchaV3Service } from 'ng-recaptcha';

import { CONFIG, RECAPTCHA_ACTIONS, DEFAULT_PORTAL_SETTINGS } from '../../environments/environment';
import { iConfigurationReceivedModel } from 'src/interfaces/iConfigurationReceivedModel.i';
import { iGeneralResponse } from 'src/interfaces/iGeneralResponse.i';
import { Observable, Subject } from 'rxjs';
import { iSystemConfiguration } from 'src/interfaces/iSystemConfiguration.i';

// 3rd party
import * as _ from 'lodash';

// interfaces
import { iPromotionConfiguration } from '../../interfaces/iPromotionConfiguration.i';
import { iFuelTypes } from 'src/interfaces/iFuelTypes.i';
import { PortalGeneralConfig } from 'src/interfaces/PortalGeneralConfig.i';
import {LocalConfigurations} from "../../interfaces/configs/LocalConfigurations.i";

@Injectable({
  providedIn: 'root'
})
export class PortalConfigService {
  public isConfigured: boolean;
  public wholeConfig: ParsedConfig;
  private configSubscription: Subject<any>;
  private contactHash: string;
  private fuelTypes: iFuelTypes[];
  private localConfigurations: LocalConfigurations;

  private portalConfiguration: any = {
    General: {
      Background: 'green'
    },
    home: {

    }
  };

  constructor(
      private _http: HttpClient,
      private reCaptcha: ReCaptchaV3Service,
      @Inject(DOCUMENT) private _document: HTMLDocument,
  ) {
    this.isConfigured = false;
    this.configSubscription = new Subject();
  }

  public subscribeToUpdates(): Observable<any> {
    if (this.isConfigured) {
      setTimeout(() => {
        this.configSubscription.next();
      }, 1);
    }
    return this.configSubscription;
  }

  public getHash(): string {
    return this.contactHash;
  }

  public setHash(hash: string): void {
    this.contactHash = hash;
  }

  private sendUpdates(): void {
    this.configSubscription.next();
  }

  public getTermsAndConditionsHTML(): Promise<any> {
    const uri = this.portalConfiguration.tyc;
    return this._http.get(`./assets/tyc/${uri}`, { responseType: 'text' }).toPromise();
  }

  public setFuelTypes(fuelTypes: iFuelTypes[]): void {
    this.fuelTypes = fuelTypes;
  }

  public getFuelTypes(): iFuelTypes[] {
    return this.fuelTypes;
  }

  public getLocalConfigurations(): LocalConfigurations {
    return this.localConfigurations;
  }

  private fetchLocalConfigurations(): void {
    try {
      this._http.get<LocalConfigurations>('assets/configs/Configurations.json').toPromise()
          .then((data) => {
            this.localConfigurations = data;
          });
    } catch (e) {
      console.warn('No se pueden cargar las configuraciones locales', e);
    }
  }
  public init(hash: string): Promise<any> {
    this.fetchLocalConfigurations();
    return new Promise(async (resolve, reject) => {
      if (!hash) {
        const err = this.buildCustomError('No se encontró hash');
        return reject(err);
      }
      this.contactHash = hash;
      try {
        const reCaptchaResult: string = await this.reCaptcha.execute(RECAPTCHA_ACTIONS.GET_PORTAL_CONFIGURATION).toPromise();
        const result: iGeneralResponse = await this._http.post<iGeneralResponse>(`${CONFIG.API.BASE_URL}/${CONFIG.API.PATHS.PORTAL_CONFIGURATIONS}`, {},
            {
              headers: {
                recaptcha: reCaptchaResult
              }
            }).toPromise();

        const reCaptchaResult2: string = await this.reCaptcha.execute(RECAPTCHA_ACTIONS.SYSTEM_CONFIG).toPromise();
        const systemConfig: iGeneralResponse = await this._http.post<iGeneralResponse>(`${CONFIG.API.BASE_URL}/${CONFIG.API.PATHS.SYSTEM_CONFIGURATIONS}`, {}, {
          headers: {
            recaptcha: reCaptchaResult2
          }
        }).toPromise();

        this.parseConfiguration(result.Data, systemConfig.Data);
        return resolve();
      } catch (e) {
        return reject(e);
      }
    });
  }

  public getMainBackground(): any {
    return {
      'background': this.portalConfiguration.General.Background
    };
  }

  public getHomeConfiguration(): any {
    return this.portalConfiguration.home;
  }

  public getBenefitsConfiguration(): any {
    return this.portalConfiguration.benefitAds;
  }

  public getPurchaseGiftsConfiguration(): any {
    return this.portalConfiguration.purchaseGiftsConfiguration;
  }

  public getPaymentMethodsConfiguration(): any {
    return this.portalConfiguration.paymentMethods;
  }

  public getMainFooterConfiguration(): any {
    return this.portalConfiguration.mainFooter;
  }

  public getSecondaryFooterConfiguration(): any {
    return this.portalConfiguration.secondaryFooter;
  }

  public getHeaderConfiguration(): any {
    return this.portalConfiguration.header;
  }

  public getGeneralConfiguration(): PortalGeneralConfig {
    return this.portalConfiguration.general;
  }

  public getPararelaReturnConfiguration(): any {
    return this.portalConfiguration.pasarelaReturn;
  }

  public getCompletaYVerificaPageConfiguration(): any {
    return this.portalConfiguration.completaYVerificaPage;
  }

  public getPromotioConfiguration(): iPromotionConfiguration {
    return this.portalConfiguration.promotionConfiguration;
  }

  public getSystemConfigurations(): iSystemConfiguration {
    return this.portalConfiguration.systemConfig;
  }

  public getHolderConfiguration(): any {
    return this.portalConfiguration.holderConfiguration;
  }

  public getErrorMessages(): any {
    return this.portalConfiguration.errorMessages;
  }

  public getPage2Configuration(): any {
    return this.portalConfiguration.page2Configuration;
  }

  public getPage3Configuration(): any {
    return this.portalConfiguration.page3Configuration;
  }

  public setFavicon(iconName: string = DEFAULT_PORTAL_SETTINGS.DEFAULT_FAVICON_FILE): void {
    const basepath: string = DEFAULT_PORTAL_SETTINGS.DEFAULT_FAVICON_LOCATION;
    const faviconElement = this._document.getElementById('injected-favicon');
    const fullPath = `${basepath}/${iconName}`;

    if (faviconElement) {
      faviconElement.setAttribute('href', fullPath);
    }
    else {
      const iconExtension = iconName.split('.').pop();
      const iconType = (iconExtension == 'ico') ? 'image/x-icon': 'image/png';

      const newFaviconElement = this._document.createElement('link');
      newFaviconElement.setAttribute('type', iconType);
      newFaviconElement.setAttribute('rel', 'icon');
      newFaviconElement.setAttribute('href', fullPath);
      newFaviconElement.setAttribute('id', 'injected-favicon');
      const head = this._document.getElementsByTagName('head');
      head[0].appendChild(newFaviconElement);
    }
  }

  /**
   *
   * @param config Una configuración a chequear.
   * @param mainConfig La configuración por la que se reemplaza la original.
   *
   * @description
   * Función recursiva.
   * 1. Chequeo: Si la mainConfig es null, quiere decir que se reemplaza todo con la subConfig.
   * 2. Por cada propiedad que se envíe, chequea si es objeto (si tiene propiedades anidades), si es un objeto,
   *    vuelve a llamarse a sí misma hasta llegar a una configuración sin propiedades anidadas.
   * 3. Si no es objeto, quiere decir que es una propiedad final con un valor. Si este valor es null, se setea la
   *    configuración subConfig, sino se mantiene la mainConfig.
   *
   *
   */
  private innerPaser(mainConfig, subConfig): any {
    if (mainConfig === null) {
      return subConfig;
    }
    _.forOwn(mainConfig, (value, key) => {
      if (_.isObject(value)) {
        return this.innerPaser(value, subConfig[key]);
      }
      else {
        _.mergeWith(mainConfig, subConfig,
            (a, b) => {
              return (a === null) ? b : a;
            });
      }
    });

    return mainConfig;
  }

  private parseConfiguration(configuration: iConfigurationReceivedModel[], systemConfig: iSystemConfiguration): void {
    let baseConfig;

    if (configuration.length > 1) {
      const companyConfig = configuration[0];
      const intermediaryConfig = configuration[1];


      baseConfig = {};

      // por cada propiedad del intermediario, chequeamos con la de la compañía.
      _.forOwn(intermediaryConfig, (config, key) => {
        baseConfig[key] = this.innerPaser(config, companyConfig[key]);
      });

    }
    else {
      baseConfig = configuration[0];
    }
    this.wholeConfig = baseConfig;

    // parseo home
    this.portalConfiguration.home = {
      header_color: baseConfig.General.Background,
      logo: baseConfig.General.Logo,
      title: baseConfig.Page1.Title,
      subtitle: baseConfig.Page1.Subtitle,
      image: baseConfig.Page1.Image1
    };

    this.portalConfiguration.benefitAds = baseConfig.Ads;
    this.portalConfiguration.purchaseGiftsConfiguration = baseConfig.BenefitsConfiguration;
    this.portalConfiguration.paymentMethods = baseConfig.PaymentConfigurations;
    this.portalConfiguration.secondaryFooter = baseConfig.Footer1;
    this.portalConfiguration.mainFooter = baseConfig.Footer2;
    this.portalConfiguration.pasarelaReturn = {
      success: baseConfig.SuccessfullyPage,
      missingSoat: baseConfig.MissingPaymentPage,
      error: baseConfig.ErrorPage
    };
    this.portalConfiguration.completaYVerificaPage = baseConfig.Page4;
    this.portalConfiguration.completaYVerificaPage.paymentMessage = baseConfig.General.CreditCardPaymentMessage || '';
    this.portalConfiguration.errorMessages = baseConfig.ErrorMessages;

    // header config
    // TODO: Ver datos en duro
    this.portalConfiguration.header = {
      chatNow: 'Chatea ahora',
      chatNumber: systemConfig.WhatsappNumberChat,
      chatActivated: systemConfig.ActivateWhatsappNumberChat,
      callMe: baseConfig.General.CallMe || '¿Te llamamos?',
      logo: baseConfig.General.Logo,
      logoTitle: baseConfig.General.LogoTitle,
      clickToCallConfig: baseConfig.ClickToCallConfiguration,
      enableLogo: baseConfig.General.EnableLogo
    }

    // términos y condiciones
    this.portalConfiguration.tyc = baseConfig.TermsAndConditions;

    // banners
    this.portalConfiguration.promotionConfiguration = baseConfig.PromotionConfiguration;

    // configuraciones extra
    this.portalConfiguration.systemConfig = systemConfig;

    // Configuración Regimen Type enabled/disabled
    this.portalConfiguration.holderConfiguration = baseConfig.HolderConfiguration;

    // Configuración Page2 (Completa los datos)
    this.portalConfiguration.page2Configuration = baseConfig.Page2;

    // Configuración Page3 (Verifica los datos)
    this.portalConfiguration.page3Configuration = baseConfig.Page3;

    this.portalConfiguration.systemConfig = systemConfig;

    this.portalConfiguration.general = baseConfig.General;

    this.isConfigured = true;

    // avisamos que hay config!
    this.sendUpdates();
  }

  private buildCustomError(message: string): Object {
    return {
      Data: null,
      Success: false,
      Message: message
    };
  }
}
