import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { ModalController } from '@ionic/angular';

import { ISoatService } from '../../services/i-soat.service';

// env
import { VALIDATIONS, DOCUMENT_TYPES } from '../../../environments/environment';

// interfaces
import { iDocumentTypes } from 'src/interfaces/iDocumentTypes.i';

@Component({
  selector: 'app-click-to-call-modal',
  templateUrl: './click-to-call-modal.component.html',
  styleUrls: ['./click-to-call-modal.component.scss'],
})
export class ClickToCallModalComponent implements OnInit {
  @Input('contactData') contactData;

  public documentTypes: iDocumentTypes[];
  public contactForm: FormGroup;
  public submitAttempt: boolean;

  constructor(
    private _modalController: ModalController,
    private _formBuilder: FormBuilder,
    private iSoat: ISoatService,
  ) {

    this.submitAttempt = false;
  }

  ngOnInit() {
    this.initConfig();
  }

  onSubmit(): void {
    this.sendContactData();
  }

  private async sendContactData(): Promise<void> {
    this.submitAttempt = true;
    if (this.contactForm.invalid) {
      return;
    }
    this.dismiss(this.contactForm.value);
  }

  private async initConfig(): Promise<void> {
    this.documentTypes = this.iSoat.getDocumentConfigurations();

    if (!this.documentTypes) {
      await this.getDocumentTypes();
    }

    this.documentTypes = this.iSoat.getDocumentConfigurations();
    if (this.documentTypes) {
      this.buildForm();
      this.checkSavedConctactData();
    }
  }

  public async dismiss(data): Promise<void> {
    if (data) {
      data.documentTypeObject = this.documentTypes.find((document) => { return document.DocumentTypeId == data.documentType });
    }
    await this._modalController.dismiss({ contactData: data }, 'button');
  }

  private buildForm(): void {
    this.contactForm = this._formBuilder.group({
      documentType: [1, [Validators.required]],
      documentNumber: [null, [Validators.required]],
      firstName: [null, [Validators.required]],
      firstName1: [''],
      lastName: [null, [Validators.required]],
      lastName1: [''],
      email: [null, [Validators.required, Validators.pattern(VALIDATIONS.EMAIL)]],
      cellphone: [null, [Validators.required, Validators.pattern(VALIDATIONS.CELL_PHONE)]],
    });
  }

  private checkSavedConctactData(): void {
    const savedContactData = this.iSoat.getSavedContact();
    if (savedContactData) {
      this.contactF.firstName.setValue(savedContactData.FirstName || null);
      this.contactF.firstName1.setValue(savedContactData.FirstName1 || null);

      // si es NIT no seteamos apellido
      if (savedContactData.DocumentTypeId != DOCUMENT_TYPES.NIT) {
        this.contactF.lastName.setValue(savedContactData.LastName || null);
      }

      this.contactF.lastName1.setValue(savedContactData.LastName1 || null);

      this.contactF.email.setValue(savedContactData.Email || null);
      this.contactF.cellphone.setValue(savedContactData.Cellular || null);
      this.contactF.documentType.setValue(savedContactData.DocumentTypeId || null);
      this.contactF.documentNumber.setValue(savedContactData.DocumentNumber || null);
    }
  }

  private getDocumentTypes(): Promise<any> {
    return this.iSoat.getDocumentTypes();
  }

  get contactF(): { [key: string]: AbstractControl } { return this.contactForm.controls; }

}
