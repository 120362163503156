import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { MessageModalComponent } from './message-modal.component';

@NgModule({
    imports: [
        IonicModule,
        CommonModule
    ],
    declarations: [MessageModalComponent],
    exports: [MessageModalComponent],
    entryComponents: [MessageModalComponent]
})
export class MessageModalComponentModule { }
