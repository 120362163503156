import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { PromotionsModalComponent } from './promotions-modal.component';

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
    ],
    declarations: [PromotionsModalComponent],
    exports: [PromotionsModalComponent],
    entryComponents: [PromotionsModalComponent]
})
export class PromotionsModalComponentModule { }
