import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { TycModalComponent } from './tyc-modal.component';

@NgModule({
  imports: [
    IonicModule,
    CommonModule
  ],
  declarations: [TycModalComponent],
  exports: [TycModalComponent],
  entryComponents: [TycModalComponent]
})
export class TycModalComponentModule { }
