import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// reCaptcha
import { ReCaptchaV3Service } from 'ng-recaptcha';

import {
  CONFIG,
  SOAT_OPERATIONS,
  EMISION_TYPE,
  LOCAL_STORAGE_LOCATIONS,
  environment,
  RECAPTCHA_ACTIONS,
  DOCUMENT_TYPES,
  ENGINE_POWER_TYPE_ID, SOATServiceTypeID
} from '../../environments/environment';

import { addYears, addDays, setHours, setMinutes, setSeconds, addSeconds, isAfter, subDays, addHours } from 'date-fns';

import { LocalStorageService } from './local-storage.service';

// 3rd party
import { every, isNull } from 'lodash';

// interfaces
import { iGetContactModel } from '../../interfaces/iGetContactModel.i';
import { iGetTariffModel } from 'src/interfaces/iGetTariff.i';
import { iCalculateExistInsurancePolicyModel } from 'src/interfaces/iCalculateExistInsurancePolicyModel.i';
import { iPaymentMethodAllowedModel, PaymentMethod } from 'src/interfaces/iPaymentMethodAllowedModel.i';
import { iGenerateNewPolicyModel } from 'src/interfaces/iGenerateNewPolicyModel.i';
import { iVehicleData } from 'src/interfaces/iVehicleData.i';
import { iContactData } from 'src/interfaces/iContactData.i';
import { iGetInsurancePoliciesModel } from 'src/interfaces/iGetInsurancePoliciesModel.i';
import { iGeneralResponse } from 'src/interfaces/iGeneralResponse.i';
import { iVehicleMinistryAndServices } from 'src/interfaces/iVehicleMinistryAndServices.i';
import { iDocumentTypes } from 'src/interfaces/iDocumentTypes.i';
import { iSaveContactFormData } from 'src/interfaces/iSaveContactFormData.i';
import { iGetTariffResponse } from 'src/interfaces/iGetTariffResponse.i';
import { iGetPersonRequest } from 'src/interfaces/iGetPersonRequest.i';
import { iCity } from 'src/interfaces/iCity.i';
import { iPolicyDates } from 'src/interfaces/iPolicyDates.i';
import { iLocalBudgetRefence } from 'src/interfaces/iLocalBudgetReference.i';
import { iValidityItems } from 'src/interfaces/iValidityItems.i';
import { iBenefit } from 'src/interfaces/iBenefit.i';
import { iClickToCallModelRequest } from 'src/interfaces/iClickToCallModelRequest.i';
import { iGetNationalCardDataModel } from 'src/interfaces/iGetNationalCardDataModel.i';
import { iVehicleResponseI } from '../../interfaces/vehicle/iVehicleResponse.i';

// const vehicleDataTest: iVehicleData = { VehicleId: 5662860, NumberPlate: "HVW365", VehicleYear: 2014, MotorNumber: "LEA122900797", ChasisNumber: "KL1PM9CT4EK500260", VehicleClassMinistryCode: "0", Vin: "KL1PM9CT4EK500260", CylinderCapacity: 2384, LoadCapacity: 0, PassengerCapacity: 7, BrandId: 16, BrandName: "CHEVROLET", BrandCode: "1", VehicleLineId: 21078, VehicleLineDescription: "ORLANDO", VehicleLineCode: "18174", VehicleClassId: 6, VehicleClassName: "VEHICULO PARA 6 O MAS PASAJEROS", VehicleClassMinistryId: 50, VehicleClassMinistryName: "CAMIONETA", ServiceTypeId: 1, ServiceTypeName: "PARTICULAR", VehicleBodyTypeId: 234, VehicleBodyTypeName: "WAGON", CountryId: 57, IsLocked: 2, CityId: 11001, CityName: "BOGOTA D.C", NewTariff: { TariffCode: "611", InsurancePremium: 376700, InsuranceTax: 188350, InsuranceFine: 1400, Total: 566450, TotalWithDiscountAmount: 566450, DiscountAmount: 0, InsurancePremiumFormatted: "$376,700", InsuranceTaxFormatted: "$188,350", InsuranceFineFormatted: "$1,400", TotalFormatted: "$566,450", DiscountAmountFormatted: "$0", TotalWithDiscountAmountFormatted: "$566,450" }, Expiration: { FromValidateDate: "2019-06-19T00:00:00", DueValidateDate: "2020-06-18T23:59:00", ExpirationDays: 343 }, Blocked: { IsBlockedBrand: true, IsBlockedBrandLine: true, IsBlockedVehicleClass: true, IsBlockedVehicleClassMinistry: true, IsBlockedVehicleBodyType: true, IsBlockedServiceType: true, IsBlockedVehicleYear: true, IsBlockedCylinderCapacity: true, IsBlockedPassengerCapacity: true, IsBlockedLoadCapacity: true, IsBlockedMotorNumber: true, IsBlockedChasisNumber: true, IsBlockedVIN: true } };

@Injectable({
  providedIn: 'root'
})
export class ISoatService {
  // datos del proceso
  private vehicleData: iVehicleData;
  private contactData: iContactData;
  private tariffData: iGetTariffResponse;
  private policyDates: iPolicyDates;
  private invalidInsuranceValidations: iValidityItems;
  private selectedBenefit: iBenefit;

  // este original tariff se setea en la primer pantalla cuando pedimos el primer tariff.
  // esto es para que cuando en los pasos siguientes volvamos a pedir la tarifa podamos validar 
  // el TariffCode
  private originalTariff: iGetTariffResponse;

  // datos de configuración
  private vehicleConfigurations: iVehicleMinistryAndServices;
  private documentTypes: iDocumentTypes[];
  private cities: iCity[];

  // modelos de entrada a los métodos
  private contactLog: iSaveContactFormData;

  constructor(
      private _http: HttpClient,
      private storage: LocalStorageService,
      private reCaptcha: ReCaptchaV3Service,
  ) {
    // TODO: Sacar este mock.
    // const mock = false;
    // if (mock === true) {
    // this.vehicleData = { "VehicleId": 5664350, "NumberPlate": "HVW472", "VehicleYear": 2014, "MotorNumber": null, "ChasisNumber": "3C4PDCAB7ET183654", "Vin": "3C4PDCAB7ET183654", "CylinderCapacity": 2400, "LoadCapacity": 0, "PassengerCapacity": 5, "BrandId": 24, "BrandName": "DODGE", "BrandCode": "10", "VehicleLineId": 17815, "VehicleLineDescription": "JOURNEY SE", "VehicleLineCode": "8086", "VehicleClassId": 2, "VehicleClassName": "CAMPEROS O CAMIONETAS", "VehicleClassMinistryId": 15, "VehicleClassMinistryName": "CAMIONETA", "ServiceTypeId": 1, "ServiceTypeName": "PARTICULAR", "VehicleBodyTypeId": 234, "VehicleBodyTypeName": "WAGON", "CountryId": 57, "IsLocked": 0, "CityId": 11001, "CityName": "BOGOTA D.C", "NewTariff": { "TariffCode": "221", "InsurancePremium": 447400, "InsuranceTax": 223700, "InsuranceFine": 1400, "Total": 672500, "TotalWithDiscountAmount": 672500, "DiscountAmount": 0, "InsurancePremiumFormatted": "$447,400", "InsuranceTaxFormatted": "$223,700", "InsuranceFineFormatted": "$1,400", "TotalFormatted": "$672,500", "DiscountAmountFormatted": "$0", "TotalWithDiscountAmountFormatted": "$672,500" }, "Expiration": null, "Blocked": { "IsBlockedBrand": false, "IsBlockedBrandLine": false, "IsBlockedVehicleClass": false, "IsBlockedVehicleClassMinistry": false, "IsBlockedVehicleBodyType": false, "IsBlockedServiceType": false, "IsBlockedVehicleYear": false, "IsBlockedCylinderCapacity": false, "IsBlockedPassengerCapacity": false, "IsBlockedLoadCapacity": false, "IsBlockedMotorNumber": false, "IsBlockedChasisNumber": false, "IsBlockedVIN": false } };
    //   this.contactData = { "ContactId": 3451999, "DocumentTypeId": 1, "DocumentNumber": "33648531", "LastName": "RETAMAR", "LastName1": null, "FirstName": "ARIEL", "FirstName1": null, "Name": "RETAMAR, ARIEL", "LastNames": "RETAMAR", "FirstNames": "ARIEL", "Phone": "3517555555", "Cellular": "3516848142", "Email": "ari.retamar@gmail.com", "Address": { "AddressId": 8340266, "ContactId": 3451999, "Name": "CL AVNEIDA 123", "AddressType": 1, "CityId": 11001, "CityName": "BOGOTA D.C", "StateId": 11, "StateName": "BOGOTA", "CountryId": 57 }, "CiiuId": 10, "ContactPersonTypeId": null, "IsLocked": 0 };
    //   this.tariffData = {"TariffCode":"221","InsurancePremium":447400,"InsuranceTax":223700,"InsuranceFine":1400,"Total":672500,"TotalWithDiscountAmount":672500,"DiscountAmount":0,"InsurancePremiumFormatted":"$447,400","InsuranceTaxFormatted":"$223,700","InsuranceFineFormatted":"$1,400","TotalFormatted":"$672,500","DiscountAmountFormatted":"$0","TotalWithDiscountAmountFormatted":"$672,500"};
    //   this.originalTariff = {"TariffCode":"221","InsurancePremium":447400,"InsuranceTax":223700,"InsuranceFine":1400,"Total":672500,"TotalWithDiscountAmount":672500,"DiscountAmount":0,"InsurancePremiumFormatted":"$447,400","InsuranceTaxFormatted":"$223,700","InsuranceFineFormatted":"$1,400","TotalFormatted":"$672,500","DiscountAmountFormatted":"$0","TotalWithDiscountAmountFormatted":"$672,500"};
    // }
  }

  /**
   *
   * @param plate string placa del vehículo
   * @param goAgainstRUNT boolean Define si la búsqueda la hace contra el RUNT o no.
   */
  public async getPlate(plate: string, goAgainstRUNT: boolean = false): Promise<iVehicleResponseI> {
    if (!plate) {
      return Promise.reject('Missing arguments')
    }
    let configuration: any = {}
    configuration.NumberPlate = plate;
    configuration.CountryCode = 57;

    if (goAgainstRUNT === false) {
      configuration.RuntVehicleConfiguration = 1
    }

    try {
      const reCaptchaResult: string = await this.reCaptcha.execute(RECAPTCHA_ACTIONS.GET_VEHICLE).toPromise();
      return this._http.post<iVehicleResponseI>(`${CONFIG.API.BASE_URL}/${CONFIG.API.PATHS.GET_VEHICLE}`, configuration, {
        headers: {
          recaptcha: reCaptchaResult
        }
      }).toPromise();
    } catch (e) {
      const err = this.buildCustomError('Ha ocurrido un error, vuelva a intentarlo por favor');
      return Promise.reject(e);
    }
  }

  public async getDocumentTypes(): Promise<any> {
    try {
      const reCaptchaResult: string = await this.reCaptcha.execute(RECAPTCHA_ACTIONS.GET_DOCUMENT_TYPES).toPromise();
      const result = await this._http.post<iGeneralResponse>(`${CONFIG.API.BASE_URL}/${CONFIG.API.PATHS.DOCUMENT_TYPES}`, {},
          {
            headers: {
              recaptcha: reCaptchaResult
            }
          }).toPromise();
      if (result.Success === true && result.Data) {
        this.successGetDocumentTypes(result);
        return Promise.resolve();
      }
      else {
        return Promise.reject();
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getContact(data: iGetContactModel): Promise<any> {
    if (!data.DocumentNumber || !data.DocumentType) {
      return Promise.reject('Missing arguments');
    }

    const reCaptchaResult: string = await this.reCaptcha.execute(RECAPTCHA_ACTIONS.GET_CONTACT).toPromise();

    return this._http.post(`${CONFIG.API.BASE_URL}/${CONFIG.API.PATHS.GET_CONTACT}`, data, {
      headers: {
        recaptcha: reCaptchaResult
      }
    }).toPromise().then((res: iGeneralResponse) => {
      if (res.Data) {
        this.contactData = res.Data;

        // por habeas data borramos los datos sensibles y usamos los ingresados por el usuario
        this.contactData.Email = data.Email;
        this.contactData.DocumentNumber = data.DocumentNumber;
        this.contactData.DocumentTypeId = data.DocumentType;
        this.contactData.Cellular = data.Cellular;

        // https://bitsion.atlassian.net/browse/SOAT-2109
        if (!this.contactData.CiiuId || this.contactData.CiiuId === 0) {
          this.contactData.CiiuId = 10;
        }
      }
      else {
        // si no existen datos, almacenamos los que vienen en los argumentos.
        this.contactData = {
          Email: data.Email,
          DocumentTypeId: data.DocumentType,
          DocumentNumber: data.DocumentNumber,
          Address: null,
          Cellular: data.Cellular,
          CiiuId: null,
          ContactId: null,
          ContactPersonTypeId: null,
          FirstName: null,
          FirstName1: null,
          FirstNames: null,
          IsLocked: null,
          LastName: null,
          LastName1: null,
          LastNames: null,
          Name: null,
          Phone: null
        };

        // Update tipos de campos según JIRA INP-39 (https://bitsion.atlassian.net/browse/INP-39)

        // NIT
        if (this.contactData.DocumentTypeId === DOCUMENT_TYPES.NIT) {
          this.contactData.CiiuId = 7499;
          this.contactData.ContactPersonTypeId = 5;
        }
        // otros tipos de documento
        else {
          this.contactData.CiiuId = 10;
          this.contactData.ContactPersonTypeId = null;
        }
      }

      return res;
    });
  }

  public async getTariff(data: iGetTariffModel): Promise<any> {
    if (typeof (data.CylinderCapacity) == 'undefined' ||
        typeof (data.LoadCapacity) == 'undefined' ||
        typeof (data.PassengerCapacity) == 'undefined' ||
        typeof (data.VehicleClassId) == 'undefined' ||
        typeof (data.VehicleYear) == 'undefined' ||
        typeof (data.EnginePowerTypeId) == 'undefined') {
      return Promise.reject('Missing arguments');
    }

    // además de devolver la promesa con el resultado o error, también la analizamos para guardar
    // internamente los valores que nos devuelve.
    const reCaptchaResult: string = await this.reCaptcha.execute(RECAPTCHA_ACTIONS.GET_TARIFF).toPromise();
    return this._http.post(`${CONFIG.API.BASE_URL}/${CONFIG.API.PATHS.GET_TARIFF}`, data, {
      headers: {
        recaptcha: reCaptchaResult
      }
    }).toPromise().then(
        (res: iGeneralResponse) => {
          if (res.Data) {
            this.tariffData = res.Data;
          }
          return res;
        });
  }

  public async calculateExistInsurancePolicyModel(data: iCalculateExistInsurancePolicyModel): Promise<any> {
    const reCaptchaResult: string = await this.reCaptcha.execute(RECAPTCHA_ACTIONS.CALCULATE_EXIST_IN_INSURANCE_POLICY).toPromise();
    return this._http.post(`${CONFIG.API.BASE_URL}/${CONFIG.API.PATHS.CALCULATE_EXIST_IN_INSURANCE_POLICY}`, data, {
      headers: {
        recaptcha: reCaptchaResult
      }
    })
        .toPromise();
  }

  public getFromDate(date: Date): Date {
    const newDate = addDays(setHours(setMinutes(setSeconds(date, 0), 0), 0), 1);
    return newDate;
  }

  public getToDate(date: Date, removeOneDay = false): Date {
    let newDate;
    if (removeOneDay === false) {
      newDate = addYears(setHours(setMinutes(setSeconds(date, 59), 59), 23), 1);
    }
    else {
      newDate = subDays(addYears(setHours(setMinutes(setSeconds(date, 59), 59), 23), 1), 1);
    }
    return newDate;
  }

  /**
   * @description
   * Emite presupuesto.
   */
  public async generateNewPolicyBudget(): Promise<any> {
    const vehicleData: iVehicleData = this.vehicleData;
    const contactData: iContactData = this.contactData;
    const policyDates: iPolicyDates = this.policyDates;

    let model: iGenerateNewPolicyModel;

    if (!vehicleData || !contactData || !policyDates) {
      return Promise.reject('Missing arguments');
    }

    model = this.getDatafonoPolicyModel(vehicleData, contactData, policyDates, {}, true);

    const reCaptchaResult: string = await this.reCaptcha.execute(RECAPTCHA_ACTIONS.EMIT_BUDGET).toPromise();
    return this._http.post(`${CONFIG.API.BASE_URL}/${CONFIG.API.PATHS.NEW_INSURANCE_POLICTY_BUDGET}`, model, {
      headers: {
        recaptcha: reCaptchaResult
      }
    }).toPromise();
  }

  private getDatafonoPolicyModel(vehicleData: iVehicleData, contactData: iContactData, policyDates, payMentData: any, isBudget = false): any {
    let newPolicyModel: iGenerateNewPolicyModel | any = {};
    newPolicyModel.Contact = {
      Address: contactData.Address,
      Cellular: contactData.Cellular,
      CiiuId: contactData.CiiuId,
      ContactDialog: false,
      ContactId: contactData.ContactId,
      ContactPersonTypeId: contactData.ContactPersonTypeId || null,
      DocumentNumber: contactData.DocumentNumber,
      DocumentTypeId: contactData.DocumentTypeId,
      Email: contactData.Email,
      FirstName: contactData.FirstName,
      FirstName1: contactData.FirstName1,
      FirstNames: contactData.FirstNames,
      FullName: `${contactData.LastName} ${contactData.LastName1 || ''}, ${contactData.FirstName} ${contactData.FirstName1 || ''}`,
      IsLocked: contactData.IsLocked,
      LastName: contactData.LastName,
      LastName1: contactData.LastName1,
      LastNames: contactData.LastNames,
      Phone: contactData.Cellular,
    };

    newPolicyModel.Vehicle = {
      BrandId: vehicleData.BrandId,
      ChasisNumber: vehicleData.ChasisNumber,
      CylinderCapacity: vehicleData.CylinderCapacity,
      FuelTypeId: vehicleData.FuelTypeId,
      IsBlockedBrand: vehicleData.Blocked.IsBlockedBrand,
      IsBlockedBrandLine: vehicleData.Blocked.IsBlockedBrandLine,
      IsBlockedChasisNumber: vehicleData.Blocked.IsBlockedChasisNumber,
      IsBlockedCylinderCapacity: vehicleData.Blocked.IsBlockedCylinderCapacity,
      IsBlockedLoadCapacity: vehicleData.Blocked.IsBlockedLoadCapacity,
      IsBlockedMotorNumber: vehicleData.Blocked.IsBlockedMotorNumber,
      IsBlockedPassengerCapacity: vehicleData.Blocked.IsBlockedPassengerCapacity,
      IsBlockedServiceType: vehicleData.Blocked.IsBlockedServiceType,
      IsBlockedVIN: vehicleData.Blocked.IsBlockedVIN,
      IsBlockedVehicleBodyType: vehicleData.Blocked.IsBlockedVehicleBodyType,
      IsBlockedVehicleClass: vehicleData.Blocked.IsBlockedVehicleClass,
      IsBlockedVehicleClassMinistry: vehicleData.Blocked.IsBlockedVehicleClassMinistry,
      IsBlockedVehicleYear: vehicleData.Blocked.IsBlockedVehicleYear,
      IsLocked: vehicleData.IsLocked,
      LoadCapacity: vehicleData.LoadCapacity,
      MotorNumber: vehicleData.MotorNumber,
      NationalOperationCardId: vehicleData.NationalOperationCardId || 0,
      NumberPlate: vehicleData.NumberPlate,
      PassengerCapacity: vehicleData.PassengerCapacity,
      ServiceTypeId: vehicleData.ServiceTypeId,
      VehicleClassId: vehicleData.VehicleClassId,
      VehicleClassMinistryId: vehicleData.VehicleClassMinistryId,
      VehicleDescription: vehicleData.VehicleDescription || '',
      VehicleId: vehicleData.VehicleId,
      VehicleLineId: vehicleData.VehicleLineId,
      VehicleLineDescription: vehicleData.VehicleLineDescription,
      VehicleYear: vehicleData.VehicleYear,
      VIN: vehicleData.Vin,
      VehicleBodyTypeId: vehicleData.VehicleBodyTypeId,
      EnginePowerTypeId: (vehicleData.FuelTypeId === 5) ? ENGINE_POWER_TYPE_ID.ELECTRIC : ENGINE_POWER_TYPE_ID.OTHERS,
      ActionRadio: vehicleData.ActionRadio
    };

    // según charla con tanque seteamos el address en duro si no existe contacto
    // con los datos de su CC
    if (!contactData.Address) {
      contactData.Address = {
        AddressType: 1, // CASA
        AddressId: null,
        CityId: 11001, // BOGOTA D.C
        CityName: 'BOGOTA D.C',
        ContactId: contactData.ContactId || null,
        CountryId: 57, // COLOMBIA
        Name: 'CALLE AVENIDA 123',
        StateId: 11, // BOGOTA
        StateName: 'BOGOTA'
      };
      newPolicyModel.Contact.Address = contactData.Address;
    }
    newPolicyModel.SendPolicy = {
      Address: contactData.Address.Name,
      Cellular: contactData.Cellular,
      CityId: contactData.Address.CityId,
      Email: contactData.Email
    };

    newPolicyModel.DueValidateDate = policyDates.DueValidateDate;
    newPolicyModel.FromValidateDate = policyDates.FromValidateDate;

    newPolicyModel.PaymentMode = 0;
    // newPolicyModel.SystemSource = 12; // iSoatPortatil.
    newPolicyModel.SystemSource = 4; // Nuevo PVV.

    if (isBudget === false) {
      newPolicyModel.Payments = [
        {
          InsurancePolicyPaymentsId: 0,
          PaymentAmount: payMentData.PaymentAmount,
          Observation: "",
          ReferenceNumber1: payMentData.ReferenceNumber1,
          ReferenceNumber2: payMentData.ReferenceNumber2,
          MethodOfPaymentId: payMentData.MethodOfPaymentId
        }
      ];
    }

    // añadimos las validaciones si son necesarias.
    if (this.invalidInsuranceValidations.address === true) {
      newPolicyModel.AddressConcordance = true;
    }

    if (this.invalidInsuranceValidations.email === true) {
      newPolicyModel.EmailConcordance = true;
    }

    if (this.invalidInsuranceValidations.ip === true) {
      newPolicyModel.IpConcordance = true;
    }

    // si tiene beneficio, lo metemos dentro de las propiedades a enviar
    if (this.selectedBenefit) {
      newPolicyModel.BenefitCode = this.selectedBenefit.Code;
    }

    // tipo de régimen IVA 
    if ((typeof (this.contactData.RegimenType)).toLowerCase() === 'number') {
      newPolicyModel.RegimenTypeId = this.contactData.RegimenType;
    }

    // Responsabilidad RUT
    if (typeof(this.contactData.RutId) != 'undefined' && this.contactData.RutId != null) {
      newPolicyModel.RutId = this.contactData.RutId
    }

    return newPolicyModel;
  }

  public async getVehicleBrands(): Promise<iGeneralResponse> {
    const reCaptchaResult: string = await this.reCaptcha.execute(RECAPTCHA_ACTIONS.GET_VEHICLE_BRANDS).toPromise();
    return this._http.post<iGeneralResponse>(`${CONFIG.API.BASE_URL}/${CONFIG.API.PATHS.GET_VEHICLE_BRANDS}`, {},
        {
          headers: {
            recaptcha: reCaptchaResult
          }
        }).toPromise();
  }

  public async getVehicleBrandLines(brandId: number): Promise<iGeneralResponse> {
    const reCaptchaResult: string = await this.reCaptcha.execute(RECAPTCHA_ACTIONS.GET_VEHICLE_BRAND_LINES).toPromise();
    return this._http.post<iGeneralResponse>(`${CONFIG.API.BASE_URL}/${CONFIG.API.PATHS.GET_VEHICLE_BRAND_LINES}`, { BrandId: brandId },
        {
          headers: {
            recaptcha: reCaptchaResult
          }
        }).toPromise();
  }

  public async getPerson(data: iGetPersonRequest): Promise<iGeneralResponse> {
    if (!data.NumberPlate || !data.VehicleClassId) {
      const err = this.buildCustomError('Faltan parámetros');
      return Promise.reject(err);
    }

    data.SystemSource = 4; // SIEMPRE EN 4. Significa que es por el PVV.


    const reCaptchaResult: string = await this.reCaptcha.execute(RECAPTCHA_ACTIONS.GET_PERSON).toPromise();
    return this._http.post<iGeneralResponse>(`${CONFIG.API.BASE_URL}/${CONFIG.API.PATHS.GET_PERSON}`, data,
        {
          headers: {
            recaptcha: reCaptchaResult
          }
        }).toPromise();
  }

  public reset(): void {
    this.storage.delete(LOCAL_STORAGE_LOCATIONS.CONTACT_DATA);
    this.storage.delete(LOCAL_STORAGE_LOCATIONS.EXIST_INSURANCE);
    this.storage.delete(LOCAL_STORAGE_LOCATIONS.PAYMENT_METHODS);
    this.storage.delete(LOCAL_STORAGE_LOCATIONS.SUCCESS_EMIT);
    this.storage.delete(LOCAL_STORAGE_LOCATIONS.VEHICLE_DATA);
    this.storage.delete(LOCAL_STORAGE_LOCATIONS.VEHICLE_TARIFF);
    this.storage.delete(LOCAL_STORAGE_LOCATIONS.EMISSION_TYPE);
    this.storage.delete(LOCAL_STORAGE_LOCATIONS.POLICY_QUERY_LIST);
    this.storage.delete(LOCAL_STORAGE_LOCATIONS.LAST_SESSION_DATA);
  }

  /**
   * @description
   * Valida la dirección de email contra iSoat.
   * Conecta con servicio: InsurancePolicy/ValidateEmailInsurancePolicy
   *
   * @param email { string } email para el envío de la póliza.
   */
  public async validateInsuranceEmailPolicy(email: string): Promise<iGeneralResponse> {
    if (!email) {
      const err = this.buildCustomError('No se especificó email.');
      return Promise.reject(err);
    }
    const reCaptchaResult: string = await this.reCaptcha.execute(RECAPTCHA_ACTIONS.VALIDATE_INSURANCE_EMAIL_POLICY).toPromise();
    return this._http.post<iGeneralResponse>(`${CONFIG.API.BASE_URL}/${CONFIG.API.PATHS.VALIDATE_EMAIL_INSURANCE_POLICY}`, { Email: email }, {
      headers: {
        recaptcha: reCaptchaResult
      }
    }).toPromise();
  }

  /**
   * @description
   * Valida IP para emisión contra iSoat.
   * Conecta con servicio: InsurancePolicy/ValidateIpInsurancePolicy
   */
  public async validateIpInsurancePolicy(): Promise<iGeneralResponse> {
    const reCaptchaResult: string = await this.reCaptcha.execute(RECAPTCHA_ACTIONS.VALIDATE_IP_INSURANCE_POLICY).toPromise();
    return this._http.post<iGeneralResponse>(`${CONFIG.API.BASE_URL}/${CONFIG.API.PATHS.VALIDATE_IP_INSURANCE_POLICY}`, {}, {
      headers: {
        recaptcha: reCaptchaResult
      }
    }).toPromise();
  }

  /**
   * @description
   * Valida Address para emisión contra iSoat.
   * Conecta con servicio: InsurancePolicy/ValidateAddressInsurancePolicy
   * @param address { string } Dirección para la recepción de la póliza.
   */
  public async validateAddressInsurancePolicy(address: string): Promise<iGeneralResponse> {
    if (!address) {
      const err = this.buildCustomError('No se especificó address.');
      return Promise.reject(err);
    }

    const reCaptchaResult: string = await this.reCaptcha.execute(RECAPTCHA_ACTIONS.VALIDATE_ADDRESS_INSURANCE_POLICY).toPromise();
    return this._http.post<iGeneralResponse>(`${CONFIG.API.BASE_URL}/${CONFIG.API.PATHS.VALIDATE_ADDRESS_INSURANCE_POLICY}`, { Address: address }, {
      headers: {
        recaptcha: reCaptchaResult
      }
    }).toPromise();
  }

  /**
   * Setea la información del vehículo internamente.
   * @param vehicleData
   */
  public setVehicle(vehicleData: iVehicleData | any): void {
    this.vehicleData = vehicleData;
  }

  /**
   * Actualiza la información del vehículo.
   * @param newVehicleData
   */
  public updateVehicleData(newVehicleData: iVehicleData | any): void {
    this.vehicleData = { ...this.vehicleData, ...newVehicleData };
  }

  public getVehicle(): iVehicleData {
    // // TODO: Sacar este mock
    // if (environment.noConfgApi) {
    //   return vehicleDataTest;
    // }
    return this.vehicleData;
  }

  public setOriginalTariff(tariff: iGetTariffResponse): void {
    this.originalTariff = tariff;
  }

  public getOriginalTariff(): iGetTariffResponse {
    return this.originalTariff;
  }

  public setPolicyDates(start: Date, end: Date): void {
    this.policyDates = {
      FromValidateDate: start,
      DueValidateDate: end
    };
  }

  public getPolicyDates(): iPolicyDates {
    return this.policyDates;
  }


  public getSavedContact(): iContactData {
    // if (environment.ariTest === true) {
    //   return {"ContactId":3451999,"DocumentTypeId":1,"DocumentNumber":"33648531","LastName":"RETAMAR","LastName1":"","FirstName":"ARIEL","FirstName1":"","Name":"RETAMAR, ARIEL","LastNames":"RETAMAR","FirstNames":"ARIEL","Phone":"3517555555","Cellular":"3516848142","Email":"ari.retamar@gmail.com","Address":{"AddressId":8340266,"ContactId":3451999,"Name":"CL AVNEIDA 123","AddressType":1,"CityId":11001,"CityName":"BOGOTA D.C","StateId":11,"StateName":"BOGOTA","CountryId":57},"CiiuId":10,"ContactPersonTypeId":null,"IsLocked":0};
    // }
    return this.contactData;
  }

  public delSavedContact(): void {
    this.contactData = null;
  }

  public updateSavedContact(data: any): void {
    const cityData: iCity = this.cities.find((city) => {
      return city.CityId == data.Address.CityId;
    });
    const originalContactData = JSON.parse(JSON.stringify(this.contactData));
    const newAddressNode = { ...originalContactData.Address, ...data.Address };
    this.contactData = { ...this.contactData, ...data };

    // esto es porque sino se sobrescribe lo que ya estaba y queda una sola propiedad.
    this.contactData.Address = newAddressNode;
    this.contactData.Address.CityName = cityData.CityName;
    this.contactData.Address.StateId = cityData.StateId;
    this.contactData.Address.StateName = this.contactData.Address.StateName || '';
    this.contactData.Address.CountryId = 57; // COLOMBIA
    this.contactData.Address.AddressId = this.contactData.Address.AddressId || null;
    this.contactData.Address.Name = this.contactData.Address.Name || 'CALLE AVENIDA 123';
    this.contactData.Address.AddressType = this.contactData.Address.AddressType || 1 // RESIDENCIAL;

  }

  public getSavedTariff(): iGetTariffResponse {
    return this.tariffData;
  }

  private getMethodOfPayment(name: string): any {
    const paymentMethods: PaymentMethod[] = this.storage.get(LOCAL_STORAGE_LOCATIONS.PAYMENT_METHODS);
    if (!paymentMethods) {
      return null;
    }
    return paymentMethods.find(payment => {
      return payment.Description == name;
    });
  }

  public async getVehicleClassesWithMinistriesAndServices(): Promise<void> {
    try {
      const reCaptchaResult: string = await this.reCaptcha.execute(RECAPTCHA_ACTIONS.GET_VEHICLE_CLASSES_WITH_MINISTRIES_AND_SERVICES).toPromise();
      const result = await this._http.post<iGeneralResponse>(`${CONFIG.API.BASE_URL}/${CONFIG.API.PATHS.VEHICLE_CONFIGURATIONS}`, {}, {
        headers: {
          recaptcha: reCaptchaResult
        }
      }).toPromise()
      if (result.Success === true && result.Data) {
        this.successgetVehicleClassesWithMinistriesAndServices(result);
        return Promise.resolve();
      }
      else {
        return Promise.reject();
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }

  public async getBenefits(): Promise<iGeneralResponse> {
    const reCaptchaResult: string = await this.reCaptcha.execute(RECAPTCHA_ACTIONS.GET_BENEFITS).toPromise();
    return this._http.post<iGeneralResponse>(`${CONFIG.API.BASE_URL}/${CONFIG.API.PATHS.GET_BENEFITS}`, {}, {
      headers: {
        recaptcha: reCaptchaResult
      }
    }).toPromise();
  }

  public async callClickToCall(data: iClickToCallModelRequest): Promise<any> {
    if (!data || !data.Cellular || !data.DocumentNumber || !data.DocumentType || !data.Email || !data.FirstName || !data.LastName || !data.Phone) {
      const err = this.buildCustomError('Faltan parámetros para click to call');
      return Promise.reject(err);
    }
    const reCaptchaResult: string = await this.reCaptcha.execute(RECAPTCHA_ACTIONS.CLICK_TO_CALL).toPromise();
    return this._http.post(`${CONFIG.API.BASE_URL}/${CONFIG.API.PATHS.CLICK_TO_CALL}`, data, {
      headers: {
        recaptcha: reCaptchaResult
      }
    }).toPromise();
  }

  public async getVehicleNationalOperationCards(): Promise<any> {
    const reCaptchaResult: string = await this.reCaptcha.execute(RECAPTCHA_ACTIONS.CLICK_TO_CALL).toPromise();
    return this._http.post(`${CONFIG.API.BASE_URL}/${CONFIG.API.PATHS.GET_VEHICLE_NATIONAL_OPERATION_CARDS}`, {}, {
      headers: {
        recaptcha: reCaptchaResult
      }
    }).toPromise();
  }

  public async getNationalOperationCardData(data: iGetNationalCardDataModel): Promise<any> {
    if (
        !data ||
        typeof (data.AllowTransportMode) === 'undefined' ||
        typeof (data.NationalOperationCardId) === 'undefined' ||
        typeof (data.NumberPlate) === 'undefined' ||
        typeof (data.VehicleClassMinistryCode) === 'undefined' ||
        typeof (data.Vin) === 'undefined'
    ) {
      const err = this.buildCustomError('Faltan parámetros para getNationalOperationCardData');
      return Promise.reject(err);
    }
    const reCaptchaResult: string = await this.reCaptcha.execute(RECAPTCHA_ACTIONS.CLICK_TO_CALL).toPromise();
    return this._http.post(`${CONFIG.API.BASE_URL}/${CONFIG.API.PATHS.GET_NATIONAL_OPERATION_CARD_DATA}`, data, {
      headers: {
        recaptcha: reCaptchaResult
      }
    }).toPromise();
  }

  // SETTERS y GETTERS de beneficios
  public setSelectedBenefit(benefit: iBenefit): void {
    this.selectedBenefit = benefit;
  }

  public delSelectedBenefit(): void {
    this.selectedBenefit = null;
  }

  public getSelectedBenefit(): iBenefit {
    return this.selectedBenefit;
  }
  // / SETTERS y GETTERS de beneficios

  /**
   * @description
   * Setea los campos válidos/inválidos luego de chequear Email, IP y Address antes
   * de emitir el presupuesto.
   *
   * Lo que esté en true, quiere decir que es inválido.
   *
   * Con esto luego se añade una validación a la emisión de presupuesto: generateNewPolicyBudget
   *
   * @param data { iValidityItems }
   */
  public setInvalidInsuranceValidations(data: iValidityItems): void {
    this.invalidInsuranceValidations = data;
  }

  public resetInvalidInsuranceValidations(): void {
    this.invalidInsuranceValidations = {
      address: false,
      email: false,
      ip: false,
      message: ""
    }
  }

  /**
   * @description
   * Devuelve las validaciones que han sido marcadas inválidas
   * a la hora de emitir.
   *
   * @returns { iValidityItems }
   */
  public getInvalidInsuranceValidations(): iValidityItems {
    return this.invalidInsuranceValidations;
  }

  /**
   * Guarda datos de contacto.
   * @param data
   */
  public async saveContactLog(data: iSaveContactFormData): Promise<any> {
    if (!data
        || !data.Cellular
        || !data.DocumentNumber
        || !data.DocumentType
        || !data.Email
        || !data.NumberPlate) {
      const error = this.buildCustomError('Faltan parámetros');
      return Promise.reject(error);
    }
    // guardamos internamente para uso futuro.
    this.contactLog = data;
    const reCaptchaResult: string = await this.reCaptcha.execute(RECAPTCHA_ACTIONS.SAVE_CONTACT_LOG).toPromise();
    return this._http.post(`${CONFIG.API.BASE_URL}/${CONFIG.API.PATHS.SAVE_CONTACT_LOG}`, data, {
      headers: {
        recaptcha: reCaptchaResult
      }
    }).toPromise();
  }

  public getContactLog(): iSaveContactFormData {
    return this.contactLog;
  }

  public getVehicleConfigurations(): iVehicleMinistryAndServices | any {
    return this.vehicleConfigurations;
  }

  public getDocumentConfigurations(): iDocumentTypes[] {
    return this.documentTypes;
  }

  public getSavedCities(): iCity[] {
    return this.cities;
  }

  public async getCities(): Promise<any> {
    const reCaptchaResult: string = await this.reCaptcha.execute(RECAPTCHA_ACTIONS.GET_CITIES).toPromise();
    return this._http.post(`${CONFIG.API.BASE_URL}/${CONFIG.API.PATHS.GET_CITIES}`, {}, {
      headers: {
        recaptcha: reCaptchaResult
      }
    }).toPromise().then(
        (res: iGeneralResponse) => {
          // guardamos los datos de las ciudad localmente si existen.
          if (res.Data && res.Success === true) {
            this.cities = res.Data;
          }
          return res;
        });
  }

  /**
   * @description
   * Almacena localmente una referencia de presupuesto, junto con un timestap.
   * Esto se realiza para poder verificar la redirección cuando vuelve de la pasarela de pago.
   * Ya que no hay otra manera de saber cuál es el número de referencia cuando hace el back de la pasarela
   * al haber pagado, lo almacenamos acá por X cantidad de tiempo (seteando en las environment: CONFIG.GENERAL.BUDGET_REFERENCE_TIMEOUT)
   *
   * @param reference { string }  Número de referencia de emisión de presupuesto
   */
  public setNewBudgetReference(reference: number, urlPagoOnline?: string): void {
    const timestamp = new Date().getTime();
    const data: iLocalBudgetRefence = { timestamp, reference, plate: this.vehicleData.NumberPlate, urlPagoOnline };

    if (this.selectedBenefit) {
      data.selectedBenefit = this.selectedBenefit;
    }

    window.localStorage.setItem('budgetReference', JSON.stringify(data));
  }

  public async getInsurancePolicyState(InsurancePolicyNumber: number): Promise<iGeneralResponse> {
    if (!InsurancePolicyNumber) {
      const err = this.buildCustomError('Falta parámetro: InsurancePolicyNumber');
      return Promise.reject(err);
    }
    const reCaptchaResult: string = await this.reCaptcha.execute(RECAPTCHA_ACTIONS.GET_INSURANCE_POLICY_STATE).toPromise();
    return this._http.post<iGeneralResponse>(`${CONFIG.API.BASE_URL}/${CONFIG.API.PATHS.GET_INSURANCE_POLICY_STATE}`, { InsurancePolicyNumber }, {
      headers: {
        recaptcha: reCaptchaResult
      }
    })
        .toPromise();
  }

  /**
   * @description
   * Devuelve los datos de referencia sobre un presupuesto emitido.
   * Lógica:
   * Al almacenarse los datos, se guarda con un timestamp, en este método se chequea:
   * 1. Existe data? Si no existe, retorna null.
   * 2. Se excedió el tiempo de guardado? (almacenado en environment: CONFIG.GENERAL.BUDGET_REFERENCE_TIMEOUT).
   *    Si se excedió, retorna -1.
   * 3. Si en el punto 2 no se excedió, retorna un objeto del tipo iLocalBudgetRefence
   *
   * @returns { null | iLocalBudgetRefence | -1 }
   */
  public getBudgetReference(): iLocalBudgetRefence | -1 | null {
    const data: iLocalBudgetRefence = JSON.parse(window.localStorage.getItem('budgetReference'));
    // retornamos null para indicar que no hay nada almacenado
    if (!data) {
      return null;
    }

    // añadimos los segundos configurados a la fecha actual
    const maxTime = addSeconds(new Date(), CONFIG.GENERAL.BUDGET_REFERENCE_TIMEOUT);
    const referenceDate = new Date(data.timestamp);

    // si se excedió el tiempo en el que fue seteado, devolvemos -1 indicando el error
    if (isAfter(referenceDate, maxTime)) {
      return -1;
    }

    // si todo ok, devolvemos el número de referencia :)
    return data;
  }

  /**
   * @description
   * Valida que los datos necesarios para la cotización del vehículo estén presentes.
   * Si todo está OK, devuelve true, caso contrario false.
   * Los campos a validar se configuran dentro de este servicio.
   *
   * @param data iVehicleData Datos del vehículo
   * @returns boolean Vehículo válido.
   */
  public isRuntDataValid(data: iVehicleData): boolean {
    const fieldsToValidate = [
      'VehicleYear',
      'CylinderCapacity',
      'PassengerCapacity',
      'VehicleClassId',
      'VehicleClassMinistryId',
      'ServiceTypeId',
      'CountryId',
      'IsLocked',
      'CityId'
    ];
    // lógica? lodash, para no reinventar la rueda.
    // por cada fieldToValidate, chequeamos que no sea nulo. 
    const result = every(fieldsToValidate, k => !isNull(data[k]));

    return result;
  }

  /**
   * @description
   * Elimina datos sobre el budgetReference en el storage. Esto se debe realizar al finalizar el proceso
   * de verificación cuando la pasarela hace el redirect nuevamente hacia "retorno-pasarela".
   *
   */
  public destroyBudgetReference(): void {
    window.localStorage.removeItem('budgetReference');
  }

  private successgetVehicleClassesWithMinistriesAndServices(success: iGeneralResponse): void {
    this.vehicleConfigurations = success.Data;
  }

  private successGetDocumentTypes(result: iGeneralResponse): void {
    this.documentTypes = result.Data;
  }

  private buildCustomError(message: string): Object {
    return {
      Data: null,
      Success: false,
      Message: message
    };
  }

  /**
   * @name shouldContinueInvalidRunt
   * @description Chequea si una placa fue añadida por inexistencia al RUNT, con un throttle de 12hs.
   * @param plate string placa
   */
  public shouldContinueInvalidRunt(plate: string): boolean {
    plate = plate.toUpperCase();
    const platesData: { plate: string, lastAttempt: Date }[] = this.storage.get(LOCAL_STORAGE_LOCATIONS.VEHICLE_NOT_FOUND_RUNT);
    if (!platesData) {
      return true;
    }
    else {
      const plateNotAllowedIndex = platesData.findIndex((iPlate) => {
        return iPlate.plate === plate;
      });

      // no existe placa guardada
      if (plateNotAllowedIndex === -1) {
        return true;
      }

      // existe placa guardada

      const now = new Date();
      const lastAttempt = new Date(platesData[plateNotAllowedIndex].lastAttempt);
      const lastAttempPlus12 = addHours(lastAttempt, 12);

      // si pasaron 12hs desde el ingreso de la placa, dejamops continuar
      // y la borramos de las no permitidas.
      if (isAfter(now, lastAttempPlus12)) {
        platesData.splice(plateNotAllowedIndex, 1);
        this.storage.add(LOCAL_STORAGE_LOCATIONS.VEHICLE_NOT_FOUND_RUNT, platesData);
        return true;
      }
      else {
        return false;
      }
    }
  }

  public addInvalidRunt(plate: string): void {
    plate = plate.toUpperCase();
    let platesData: { plate: string, lastAttempt: Date }[] = <{ plate: string, lastAttempt: Date }[]>this.storage.get(LOCAL_STORAGE_LOCATIONS.VEHICLE_NOT_FOUND_RUNT);
    if (!platesData) {
      platesData = [];
    }

    const alreadyExists = platesData.findIndex((plateData) => {
      return plateData.plate === plate;
    });

    if (alreadyExists === -1) {
      platesData.push({
        plate,
        lastAttempt: new Date()
      });
      this.storage.add(LOCAL_STORAGE_LOCATIONS.VEHICLE_NOT_FOUND_RUNT, platesData);
    }
  }

  /**
   * @name GetInvoicedRegimenTypes
   * @description
   * Obtiene los valores para el campo tipo de régimen IVA para
   * el combo en los datos de contacto según JIRA -> INP-64
   */
  public async getInvoicedRegimenTypes(): Promise<any> {
    const reCaptchaResult: string = await this.reCaptcha.execute(RECAPTCHA_ACTIONS.GET_INSURANCE_POLICY_STATE).toPromise();

    return this._http.post<iGeneralResponse>(`${CONFIG.API.BASE_URL}/${CONFIG.API.PATHS.GET_INVOICE_REGIMEN_TYPES}`, {}, {
      headers: {
        recaptcha: reCaptchaResult
      }
    })
        .toPromise();
  }

  public async getFuelTypes(): Promise<any> {
    const reCaptchaResult: string = await this.reCaptcha.execute(RECAPTCHA_ACTIONS.GET_INSURANCE_POLICY_STATE).toPromise();
    return this._http.post<iGeneralResponse>(`${CONFIG.API.BASE_URL}/${CONFIG.API.PATHS.GET_RUNT_FUEL_TYPES}`, {}, {
      headers: {
        recaptcha: reCaptchaResult
      }
    }).toPromise();
  }

  public async getRutTypes(): Promise<any> {
    const reCaptchaResult: string = await this.reCaptcha.execute(RECAPTCHA_ACTIONS.GET_RUT_TYPES).toPromise();
    return this._http.post<iGeneralResponse>(`${CONFIG.API.BASE_URL}/${CONFIG.API.PATHS.GET_RUTS}`, {}, {
      headers: {
        recaptcha: reCaptchaResult
      }
    }).toPromise();
  }

  /**
   * Req: https://bitsion.atlassian.net/browse/SOAT-2187
   * @param vehicleData
   */
  public canContinueWithRadioAccion(vehicleData: iVehicleData): boolean {
    if (!vehicleData) {
      console.warn('canContinueWithRadioAccion invalid vehicleData');
      return null;
    }
    return vehicleData.ServiceTypeId != SOATServiceTypeID.PUBLICO ||
        (vehicleData.ServiceTypeId === SOATServiceTypeID.PUBLICO &&
            String(vehicleData.ActionRadio).toLowerCase() === 'nacional');
  }

  public lockNationalCardOperationInIntermunicipal(vehicleData: iVehicleData): boolean {
    return vehicleData.ServiceTypeId === SOATServiceTypeID.PUBLICO &&
    String(vehicleData.ActionRadio).toLowerCase() === 'nacional';
  }

}
