import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  public add(key: string, data: any): void {
    const parsedData = JSON.stringify(data);
    return window.localStorage.setItem(key, parsedData);
  }

  public get(key: string): any {
    const result = window.localStorage.getItem(key);
    const parsedResult = JSON.parse(result);
    return parsedResult;
  }

  public delete(key: string): void {
    return window.localStorage.removeItem(key);
  }

  public deleteAll(): void {
    return window.localStorage.clear();
  }
}
