import { ErrorHandler} from "@angular/core";
import { Injectable } from '@angular/core';
import {ErrorReporterService} from "./error-reporter.service";
import {HttpErrorResponse} from "@angular/common/http";
import {ErrorReportData} from "../../interfaces/errors/ErrorReportData.i";

@Injectable({
  providedIn: 'root'
})
export class GlobalErrorHandlerService implements ErrorHandler {

  constructor(
      private errorReporter: ErrorReporterService
  ) {
  }

  handleError(error: any) {
    let data: ErrorReportData = {
      RequestURL: null,
      NumberPlate: null,
      RequestBody: null,
      UserAgent: null,
      Details: null,
      EventSource: null,
      EventName: null,
      EventType: null,
      EntityId: null,
      EntityName: null,
      ErrorCode: null,
      ContactHash: null
    };

    if (!(error instanceof HttpErrorResponse)) {
      data.EventType = 'Error';
      data.EventName = 'ErrorInCode';
      data.EventSource = 'Error';
      try {
        data.Details = error.toString();
      } catch(e) {
        data.Details = JSON.stringify(e);
      }
      if (error instanceof TypeError) {
        data.EventSource = 'TypeError';
      }

      data.UserAgent = window.navigator.userAgent;
      data.RequestBody = '';
      data.RequestURL = window.location.href;
    } else {
      console.log('otro error');
    }

    this.errorReporter.report(data);
    // const portalConfigured = this.portalConfig.isConfigured;
    // let data:ErrorReportData = null;
    // if (!portalConfigured) {
    //   data = {
    //     RequestURL: null,
    //
    //   }
    // }
  }
}
