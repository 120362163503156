// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  ariTest   : true,
  dev       : true,
  noConfgApi: false,
  production: false,
  pru       : false,
  pre       : false
};

export const CONFIG = {
  GENERAL: {
    BUDGET_REFERENCE_TIMEOUT: 6000,                                       // 1h 40m en segundos.
    RECAPTCHA_KEY           : '6LeizXsUAAAAAA3SiaYcf3cyloukqvjt2HzUr4s_'
  },
  APP: {
    HOME   : 'home',
    VERSION: '2020.06.00.02'
  },
  API: {
    BASE_URL: 'https://dev1.bitsion.com:61022/isoat/SoatNetSEApi/api/v2',
    REPORT_URL: 'https://dev1.bitsion.com:61022/isoat/SoatNetSEApi/api/LogError/LogRegister',
    PATHS   : {
      AUTH                                : 'Account/Token',
      GET_VEHICLE                         : 'Vehicle/GetVehicle',
      DOCUMENT_TYPES                      : 'Configuration/GetContactDocumentTypes',
      GET_CONTACT                         : 'Contact/GetContact',
      GET_TARIFF                          : 'Vehicle/GetTariff',
      CALCULATE_EXIST_IN_INSURANCE_POLICY : 'InsurancePolicy/CalculateExistInsurancePolicy',
      PAYMENT_METHODS                     : 'Entity/GetMethodsOfPayment',
      NEW_INSURANCE_POLICY                : 'InsurancePolicy/NewInsurancePolicy',
      NEW_INSURANCE_POLICTY_BUDGET        : 'InsurancePolicy/NewInsurancePolicyBudget',
      GET_INSURANCE_POLICIES              : 'InsurancePolicy/GetInsurancePolicies',
      GET_AUTHENTICATED_ENTITY            : 'Entity/GetEntityAuthenticated',
      VERSION_ISOAT_PORTATIL_APP          : 'Configuration/GetVersioniSoatPortatilApp',
      LAST_SESSION_DATA                   : 'Account/Profile/Session/Last',
      PORTAL_CONFIGURATIONS               : 'Configuration/GetPortalConfigurations',
      SYSTEM_CONFIGURATIONS               : 'Configuration/GetPortalSystemConfigurations',
      VEHICLE_CONFIGURATIONS              : 'Configuration/GetVehicleClassesWithMinistriesAndServices',
      SAVE_CONTACT_LOG                    : 'Contact/SaveContactLog',
      GET_BENEFITS                        : 'Benefit/GetBenefits',
      GET_PERSON                          : 'Vehicle/GetPerson',
      GET_CITIES                          : 'Configuration/GetCities',
      GET_INSURANCE_POLICY_STATE          : 'InsurancePolicy/GetInsurancePolicyState',
      VALIDATE_IP_INSURANCE_POLICY        : 'InsurancePolicy/ValidateIpInsurancePolicy',
      VALIDATE_ADDRESS_INSURANCE_POLICY   : 'InsurancePolicy/ValidateAddressInsurancePolicy',
      VALIDATE_EMAIL_INSURANCE_POLICY     : 'InsurancePolicy/ValidateEmailInsurancePolicy',
      CLICK_TO_CALL                       : 'Contact/ClickToCall',
      MOCKED_GTM                          : 'assets/google-tag-manager/gtm.dev.json',
      GET_VEHICLE_NATIONAL_OPERATION_CARDS: 'Configuration/GetVehicleNationalOperationCards',
      GET_NATIONAL_OPERATION_CARD_DATA    : 'Vehicle/GetNationalOperationCardData',
      GET_INVOICE_REGIMEN_TYPES           : 'Configuration/GetInvoicedRegimenTypes',
      GET_VEHICLE_BRANDS                  : 'Configuration/GetVehicleBrands',
      GET_VEHICLE_BRAND_LINES             : 'Configuration/GetVehicleBrandLines',
      GET_RUNT_FUEL_TYPES                 : 'Configuration/GetRUNTFuelTypes',
      GET_RUTS                            : 'Configuration/GetRuts'
    }
  },
  ENTITY_ID: 3634,
}

export const VALIDATIONS = {
  ADDRESS: {
    CONTROL_INFORMATION_MUP_ENABLED : /^[A-Za-z][A-Za-z0-9\u00f1\u00d1 -]{7,99}$/,
    CONTROL_INFORMATION_MUP_DISABLED: /^[A-Za-z0-9#\u00f1\u00d1 -]{3,40}$/
  },
  PLATE               : /^[a-zA-Z0-9]{3,10}$/,
  VIN                 : /^[0-9A-Ha-hJ-Nj-nPpR-Zr-z]{13,13}[0-9]{4}$/,
  NIT                 : /([0-9]{7,11}$)/,
  CITIZENSHIP_CARD    : /^[1-9][0-9]{3,9}$/,
  FOREIGNER_ID        : /^[1-9][0-9]{4,9}$/,
  PASSPORT            : /^[0-9a-zA-Z]{4,10}$/,
  IDENTITY_CARD       : /(^[0-9][0-9]{9,10}$)/,
  EMAIL               : /^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
  CELL_PHONE          : /(^3[0-9]{9,9}$)|(^5[0-9]{11,11}$)/,
  AUTHORIZATION_NUMBER: /^[a-z0-9]{6,6}$/i,
  VEHICLE_YEAR        : /^[0-9]{4,4}$/,
  VEHICLE_YEAR_MIN    : 1910,
  CYLINDER_CAPACITY   : /^[0-9]{0,5}$/,
  PASSENGERS          : /^[0-9]{0,3}$/,
  PASSENGERS_RANGE    : {
    MOTOS                                     : { MIN: 1, MAX: 2 },
    CAMPEROS_CAMIONETAS                       : { MIN: 1, MAX: 5 },
    CARGA_MIXTO                               : { MIN: 2, MAX: 6 },
    OFICIALES_ESPECIALES_BOMBEROS_DIPLOMATICOS: { MIN: 2, MAX: 999 },
    AUTOMOVILES_FAMILIARES                    : { MIN: 2, MAX: 5 },
    VEHICULO_PARA_6_MAS_PASAJEROS             : { MIN: 6, MAX: 60 },
    AUTOS_DE_NEGOCIOS_TAXIS                   : { MIN: 1, MAX: 12 },
    BUSES_BUSETAS                             : { MIN: 13, MAX: 300 },
    INTERMUNICIPAL                            : { MIN: 3, MAX: 180 },
    MICROBUSES                                : { MIN: 1, MAX: 12 },
    ESCOLAR_TURISMO                           : { MIN: 5, MAX: 180 },
    MOTO_CARRO                                : { MIN: 1, MAX: 999 },
    CICLOMOTOR                                : { MIN: 1, MAX: 999 }
  },
  CYLINDER_CAPACITY_RANGE: {
    MOTOS                                     : { MIN: 49, MAX: 99999 },
    CAMPEROS_CAMIONETAS                       : { MIN: 50, MAX: 99999 },
    CARGA_MIXTO                               : { MIN: 600, MAX: 99999 },
    OFICIALES_ESPECIALES_BOMBEROS_DIPLOMATICOS: { MIN: 600, MAX: 99999 },
    AUTOMOVILES_FAMILIARES                    : { MIN: 100, MAX: 99999 },
    VEHICULO_PARA_6_MAS_PASAJEROS             : { MIN: 600, MAX: 99999 },
    AUTOS_DE_NEGOCIOS_TAXIS                   : { MIN: 600, MAX: 99999 },
    BUSES_BUSETAS                             : { MIN: 600, MAX: 99999 },
    INTERMUNICIPAL                            : { MIN: 600, MAX: 99999 },
    MICROBUSES                                : { MIN: 600, MAX: 99999 },
    ESCOLAR_TURISMO                           : { MIN: 600, MAX: 99999 },
    MOTO_CARRO                                : { MIN: 50, MAX: 99999 },
    CICLOMOTOR                                : { MIN: 1, MAX: 99999 }
  },
  LOAD_CAPACITY      : /^[0-9]{0,5}$/,
  MOTOR_NUMBER_REGEX : /^[A-Za-z0-9]{2,30}$/,
  CHASIS_NUMBER_REGEX: /^[A-Za-z0-9]{2,30}$/,
  VIN_NUMBER         : {
    REGEX_1: /^[0-9A-Ha-hJ-Nj-nPpR-Zr-z]{13,13}[0-9]{4}$/,
    // REGEX_2: /(\w)\1+/
    REGEX_2: /^[0-9A-Ha-hJ-Nj-nPpR-Zr-z]{13,13}[0-9]{4}$/
  }
}

export const LOCAL_STORAGE_LOCATIONS = {
  SESSION               : 'session',
  VEHICLE_DATA          : 'vehicle_data',
  DOCUMENT_TYPES        : 'document_types',
  VEHICLE_TARIFF        : 'vehicle_tariff',
  EXIST_INSURANCE       : 'exist_insurance',
  PAYMENT_METHODS       : 'payment_methods',
  CONTACT_DATA          : 'contact_data',
  SUCCESS_EMIT          : 'success_emit_data',
  EMISSION_TYPE         : 'emission_type',
  POLICY_QUERY_LIST     : 'policy_query_list',
  ENTITY_ID             : 'entity_id',
  LAST_SESSION_DATA     : 'last_session_data',
  VEHICLE_NOT_FOUND_RUNT: 'vehicle_not_found_runt'
};

export const APP_EVENTS = {
  CONFIG_DONE: 'config:done'
}

export const SOAT_OPERATIONS = {
  EMISION    : 1,
  REEMPLAZO  : 2,
  RENOVACION : 3,
  DEVOLUCION : 4,
  REIMPRESION: 5,
  ANULACION  : 6
}

export const SERVER_ERROR_TYPES = {
  CONTROLLED    : "1",
  NOT_CONTROLLED: "2"
};

export const DOCUMENT_TYPES = {
  CITIZENSHIP_CARD: 1,
  FOREIGNER_ID    : 2,
  NIT             : 3,
  IDENTITY_CARD   : 4,
  PASSPORT        : 5
}

export const PAYMENT_TYPES = {
  EFECTIVO       : 1,
  TARJETA_CREDITO: 2
};

export enum SOAT_METHOD_OF_PAYMENT {
  EFECTIVO                 = 1,
  VISA                     = 2,
  TARJETA_AMERICAN_EXPRESS = 3,
  TARJETA_MASTERCARD       = 4,
  DINERS                   = 7,
  FINANCIAMIENTO_CODENSA   = 10,
  PAGO_ELECTRONICO         = 11,
  PAY_U                    = 12,
  IVR                      = 13,
  CREDIBANCO               = 15,
  PSE                      = 16,
  ATH                      = 17,
  BANCOLOMBIA              = 18,
  BANCO_BOGOTA             = 19,
  DAVIVIENDA               = 20,
  MULTIMEDIOS_DE_PAGO      = 21,
  EFECTIVO_CON_COMPROBANTE = 22,
  FINANCIACION             = 24,
  DESCUENTO_EFECTIVO       = 27,
  DESCUENTO_TARJETA        = 28
};

export enum CREDIT_CARD_IMAGE_LOCATIONS {
  VISA                       = 'assets/images/cards/visa.png',
  TARJETA_AMERICAN_EXPRESS   = 'assets/images/cards/american_express.png',
  FINANCIAMIENTO_CODENSA     = 'assets/images/cards/codensa.png',
  DINERS                     = 'assets/images/cards/diners.png',
  ELECTRIFICADORA_DE_PEREIRA = 'assets/images/cards/electrificadora_de_pereira.png',
  ENERTOLIMA                 = 'assets/images/cards/enertolima.png',
  EXITO                      = 'assets/images/cards/exito.png',
  TARJETA_MASTERCARD         = 'assets/images/cards/mastercard.png',
  NO_CARD                    = 'assets/images/cards/noicon.png'
};

export const EMISION_TYPE = {
  DATAFONO: 1,
  CASH: 2,
  PAYMENT_LINK: 3,
};

export const POLICY_QUERY_TYPE = {
  NO_BUDGET: 0,
  BUDGET: 1
}

export const CONFIG_INDEXES = {
  GENERAL: {
    PAGE_TITLE: 'General.Titulo'
  }
}

export const RECAPTCHA_ACTIONS = {
  GET_VEHICLE                                     : 'get_vehicle',
  GET_CITIES                                      : 'get_cities',
  GET_PERSON                                      : 'get_person',
  EMIT_BUDGET                                     : 'new_insurance_policy_budget',
  GET_DOCUMENT_TYPES                              : 'get_document_types',
  GET_CONTACT                                     : 'get_contact',
  GET_TARIFF                                      : 'get_tariff',
  CALCULATE_EXIST_IN_INSURANCE_POLICY             : 'calculate_exist_in_insurance_policy',
  GET_METHODS_OF_PAYMENT                          : 'get_methods_of_payment',
  NEW_INSURANCE_POLICY_BUDGET                     : 'new_insurance_policy_budget',
  VALIDATE_INSURANCE_EMAIL_POLICY                 : 'validate_insurance_email_policy',
  VALIDATE_IP_INSURANCE_POLICY                    : 'validate_ip_insurance_policy',
  VALIDATE_ADDRESS_INSURANCE_POLICY               : 'validate_address_insurance_policy',
  GET_PORTAL_CONFIGURATION                        : 'get_portal_configuration',
  GET_VEHICLE_CLASSES_WITH_MINISTRIES_AND_SERVICES: 'get_vehicle_classes_with_ministries_and_services',
  GET_BENEFITS                                    : 'get_benefits',
  SAVE_CONTACT_LOG                                : 'save_contact_log',
  GET_INSURANCE_POLICY_STATE                      : 'get_insurance_policy_state',
  SYSTEM_CONFIG                                   : 'get_portal_system_configurations',
  CLICK_TO_CALL                                   : 'click_to_call',
  GET_VEHICLE_BRANDS                              : 'get_vehicle_brands',
  GET_VEHICLE_BRAND_LINES                         : 'get_vehicle_brand_lines',
  FUEL_TYPE                                       : 'get_fuel_type',
  GET_RUT_TYPES                                   : 'get_rut_types'
}

export const SOATServiceTypeID = {
  PARTICULAR            : 1,
  PUBLICO               : 2,
  INTERMUNICIPAL        : 3,
  ESPECIAL              : 4,
  OFICIAL               : 5,
  PUBLICO_URBANO        : 6,
  PUBLICO_INTERMUNICIPAL: 7,
  DIPLOMATICO           : 8,
  ESPECIAL_RNMA         : 9
};

export const ELECTRIC_CAR_CONFIGURATION = {
  DOES_NOT_APPLY: 1,
  ENABLED       : 2,
  DISABLED      : 3
};

export const DEFAULT_PORTAL_SETTINGS = {
  DEFAULT_FAVICON_LOCATION: 'assets/icon',
  DEFAULT_FAVICON_FILE    : 'default-favicon.png'
};

export const ENDPOINT_ERROR_CODES = {
  GET_VEHICLE_CLASSES_WITH_MINISTRIES_AND_SERVICES: '01',
  GET_VEHICLE_BRANDS: '02',
  GET_VEHICLE_BRAND_LINES: '03',
  GET_VEHICLE: '04',
  GET_TARIFF: '05',
  GET_RUTS: '06',
  GET_RUNT_FUEL_TYPES: '07',
  GET_PORTAL_SYSTEM_CONFIGURATIONS: '08',
  GET_PORTAL_CONFIGURATION: '09',
  GET_PERSON: '10',
  GET_INVOICE_REGIMEN_TYPES: '11',
  GET_CONTACT_DOCUMENT_TYPES: '12',
  GET_CONTACT: '13',
  GET_CITIES: '14',
  GET_BENEFITS: '15',
  VALIDATE_ADDRESS_INSURANCE_POLICY: '16',
  VALIDATE_EMAIL_INSURANCE_POLICY: '17',
  VALIDATE_IP_INSURANCE_POLICY: '18',
  SAVE_CONTACT_LOG: '19',
  CALCULATE_EXIST_IN_INSURANCE_POLICY: '20',
  GET_VEHICLE_NATIONAL_OPERATION_CARDS: '21',
  NEW_INSURANCE_POLICTY_BUDGET: '22',
  GET_INSURANCE_POLICY_STATE: '23'
};

export const ENGINE_POWER_TYPE_ID = {
  OTHERS: 1,
  ELECTRIC: 2
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
