import {Component} from '@angular/core';

import {Events, Platform} from '@ionic/angular';

import { PortalConfigService } from './services/portal-config.service';
import { ISoatService } from './services/i-soat.service';
import { GoogleTagManagerService } from './services/google-tag-manager.service'

import { Router } from '@angular/router';
import { environment, CONFIG } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  private isIframe: boolean;
  private diffHost: boolean;
  public reloadEvent: any;

  constructor(
      private platform: Platform,
      private portalConfig: PortalConfigService,
      private gtm: GoogleTagManagerService,
      private _router: Router,
      private iSoat: ISoatService,
      private events: Events,
  ) {
    this.isIframe = window.self !== window.top;
    if (this.isIframe) {
      const ownHost = window.location.host;
      const parentUrl = (window.location != window.parent.location)
          ? document.referrer
          : document.location.href;
      // const parentHost = parentUrl.match(/(?:\w+\.)+\w+/);
      const parentHost = parentUrl.split('/')[2];
      this.diffHost = parentHost != ownHost;
      document.body.classList.add("is-iframe")
    }
    this.showVersionInformation();
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (!this.reloadEvent) {
        this.reloadEvent = this.events.subscribe('pvv:reload', () => {
          this.runConfig();
        });
      }
      this.runConfig();
    });
  }

  private showVersionInformation(): void {
    const appVersion = CONFIG.APP.VERSION;
    let env: string;
    if (environment.dev === true) {
      env = 'dev';
    }
    else if (environment.pru === true) {
      env = 'pru';
    }
    else if (environment.pre === true) {
      env = 'pre';
    }
    else if (environment.production === true) {
      env = 'pro';
    }
    console.log(`Running NPVV build-${appVersion}-${env}`);
  }

  private async runConfig(): Promise<void> {
    // hash a usar: BD3731A2CFE718AE5364995DE293B4E52BD23AEB-3764
    const urlParams = new URLSearchParams(window.location.search);
    // const hash = urlParams.get('ContactHash') || 'BD3731A2CFE718AE5364995DE293B4E52BD23AEB-3764';
    const hash = urlParams.get('ContactHash');

    if (hash) {
      this.portalConfig.setHash(hash);
      if (!this.portalConfig.isConfigured) {
        try {
          if (this.isIframe === false || this.diffHost === true) {
            // Buscamos las configs
            await this.portalConfig.init(hash);
            const systemConfig = this.portalConfig.getSystemConfigurations();

            // carga de GoogleTagManager si está configurado
            if (systemConfig && systemConfig.GTMCode && systemConfig.GTMCode.length > 0) {
              this.addGtm(systemConfig.GTMCode)
            }

            // favicon
            this.updateFavicon();
          }

          const returnedPasarela = this.iSoat.getBudgetReference();

          // si es distinto de null y distinto de -1, es porque
          // está viniendo de un redirect de la pasarela, llamamos
          // a la función creada en pasarela-component que está en el padre
          // otra validación es preguntar si está dentro de un iframe o no (window.self & window.top)
          // sabemos que está dentro de un iframe si window.self y window.top son distintos.
          if (returnedPasarela != null && returnedPasarela != -1 && this.isIframe) {
            try {
              // función en el contenedor del iFrame (page pasarela-component).
              // llamamos 2 veces al padre porque la pasarela está embebida 2 veces....
              window.parent.parent.postMessage('checkPaymentResults', window.parent.parent.origin);
            } catch (e) {
              console.warn('No se ha podido llamar a la función padre', e);
            }
          }
        } catch (e) {
          // cualquier error lo tomamos como not found
          this._router.navigate(['/hash-not-found'], { queryParams: { ContactHash: hash }});
          console.warn('Error config app component', e);
        }
      }
    }
    else if (this.isIframe === false) {
      // no hash y no iFrame
      this._router.navigate(['/hash-not-found'], { queryParams: { ContactHash: hash }});
    }
  }

  private addGtm(code: string): void {
    this.gtm.load(code);
  }

  private updateFavicon(): void {
    const configs = this.portalConfig.getGeneralConfiguration();
    if (configs && configs.SiteIcon) {
      this.portalConfig.setFavicon(configs.SiteIcon);
    }
    else {
      this.portalConfig.setFavicon();
    }
  }
}
