/**
 * @description
 *  Básicamente lo que hace este Guard es verificar si existe un vehículo dentro del 
 *  iSoatService, esto indica que el proceso está en proceso, y lo deja continuar en la pantalla.
 *  Si por el contrario, no se encuentra un vehículo, quiere decir que el proceso no empezó, o que el usuario
 *  refrescó la pantalla, en cuyo caso se pierde el proceso. Por lo que se lo redirecciona a la home junto
 *  con el ContactHash proporcionado.
 * 
 */

import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { ISoatService } from '../services/i-soat.service';
import { PortalConfigService } from '../services/portal-config.service';

@Injectable({
  providedIn: 'root'
})
export class HasVehicleGuard implements CanActivate {

  constructor(private _router: Router, private iSoat: ISoatService, private portalConfig: PortalConfigService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    if (!!this.iSoat.getVehicle()) {
      return true;
    }
    else {
      return this._router.parseUrl(`/?ContactHash=${this.portalConfig.getHash()}`);
    }
  }
}
