import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

// interfaces
import { iPromotionConfiguration } from 'src/interfaces/iPromotionConfiguration.i';

@Component({
  selector: 'app-promotions-modal',
  templateUrl: './promotions-modal.component.html',
  styleUrls: ['./promotions-modal.component.scss'],
})
export class PromotionsModalComponent implements OnInit {
  @Input() data: iPromotionConfiguration;

  private swiperOptions: any;

  constructor(
    private _modalController: ModalController,
  ) { }

  ngOnInit() {
    this.swiperOptions = {
      autoplay: {
        delay: this.data.TimePersSlide * 1000 // viene en secs
      },
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        renderBullet: function (index: number, className: string) {
          return '<span class="' + className + '" style="margin-right: 5px;"></span>';
        }
      },
      loop: true
    }
  }

  public close(): void {
    this._modalController.dismiss();
  }

}
