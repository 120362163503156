import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { ErrorFieldComponent } from './error-field.component';

@NgModule({
  imports: [
    IonicModule,
    CommonModule
  ],
  declarations: [ErrorFieldComponent],
  exports: [ErrorFieldComponent],
  entryComponents: [ErrorFieldComponent]
})
export class ErrorFieldComponentModule { }
