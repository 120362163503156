import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-error-field',
  templateUrl: './error-field.component.html',
  styleUrls: ['./error-field.component.scss'],
})
export class ErrorFieldComponent implements OnInit {

  constructor() { }

  ngOnInit() { }

  @Input('class') class: string;

}
