import { Component, OnInit, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-tyc-modal',
  templateUrl: './tyc-modal.component.html',
  styleUrls: ['./tyc-modal.component.scss'],
})
export class TycModalComponent implements OnInit {
  // acá viene el HTML en string
  @Input('tycHtml') tycHtml;

  // y acá se almacena ya parseado
  public parsedHtml;
  
  constructor(
    private sanitizer: DomSanitizer,
    private _modalController: ModalController,
  ) { }

  ngOnInit() {
    this.parseHtml();
  }

  private parseHtml(): void {
    this.parsedHtml = this.sanitizer.bypassSecurityTrustHtml(this.tycHtml);
  }

  public async dismiss(): Promise<void> {
    await this._modalController.dismiss();
  }


}
