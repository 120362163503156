import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpEvent, HttpResponse} from "@angular/common/http";
import {CONFIG, ENDPOINT_ERROR_CODES} from "../../environments/environment";

// interfaces
import { ErrorReportData } from "../../interfaces/errors/ErrorReportData.i";

// 3rd party
import { sha512 } from 'js-sha512';
import {PortalConfigService} from "./portal-config.service";
import {ISoatService} from "./i-soat.service";

@Injectable({
  providedIn: 'root'
})
export class ErrorReporterService {

  constructor(
      private http: HttpClient,
      private portalConfig: PortalConfigService,
      private soatService: ISoatService,
  ) { }

  public report(data: ErrorReportData): Promise<any> {
    const localConfigs = this.portalConfig.getLocalConfigurations();
    if (localConfigs && localConfigs.EnableLogReport === false) {
      return Promise.resolve();
    }
    const URL = CONFIG.API.REPORT_URL;

    if (this.portalConfig.isConfigured) {
      data.ContactHash = this.portalConfig.getHash();
      data.EntityId = this.portalConfig.wholeConfig.EntityId;
      data.EntityName = this.portalConfig.wholeConfig.EntityName;
    } else {
      data.ContactHash = '';
      data.EntityId = null;
      data.EntityName = '';
    }

    data.UserAgent = window.navigator.userAgent;

    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        if (!data[key] || typeof data[key] === 'undefined' || data[key] === undefined) {
          data[key] = '';
        }
      }
    }
    const hash = this.hashIt(data);

    return this.http.post(URL, data, {
      headers: {
        'X-Hashlog': hash
      }
    }).toPromise();
  }

  /**
   * calcula el hash
   * @param data
   * @private
   */
  private hashIt(data: ErrorReportData): string {
    // Orden en el que se hashea la data
    // ContactHash|ErrorCode|EntityName|EntityId|EventType|EventName|EventSource|UserAgent|RequestURL|NumberPlate
    const bs = `${data.ContactHash}|${data.ErrorCode}|${data.EntityName}|${data.EntityId}|${data.EventType}|${data.EventName}|${data.EventSource}|${data.UserAgent}|${data.RequestURL}|${data.NumberPlate}`;
    let hs: string;
    try {
      hs = sha512(bs);
    } catch(e) {
      console.warn('Problema al generar reporte de error.');
      return null;
    }
    return hs.toUpperCase();
  }

  // para cuando la API devuelve Sucess: false
  public handleHttpSuccessFalse(data: HttpEvent<any>): void {
    if (data instanceof HttpResponse) {
      const vehicle = this.soatService.getVehicle();
      let vehiclePlate = '';
      if (vehicle && vehicle.NumberPlate) {
        vehiclePlate = vehicle.NumberPlate;
      }
      console.log('vehicle', vehicle);
      this.report({
        EventName: 'SuccessFalse',
        EventType: 'HttpResponse',
        RequestURL: data.url,
        RequestBody: JSON.stringify(data.body) || '',
        EventSource: 'Http',
        Details: JSON.stringify(data),
        ErrorCode: '00',
        NumberPlate: vehiclePlate
      }).then();
    }
  }

  public handleHttpErrorResponse(err: HttpErrorResponse): void {
    if (err instanceof HttpErrorResponse) {
      const vehicle = this.soatService.getVehicle();
      let vehiclePlate = '';
      if (vehicle && vehicle.NumberPlate) {
        vehiclePlate = vehicle.NumberPlate;
      }
      const errorCode = this.getEndpointsErrorCode(err.url);
      this.report({
        EventName: 'HttpError',
        EventType: 'HttpErrorResponse',
        RequestURL: err.url,
        RequestBody: '',
        EventSource: 'Http',
        Details: JSON.stringify(err),
        ErrorCode: errorCode,
        NumberPlate: vehiclePlate
      }).then();
    }
  }

  private getEndpointsErrorCode(url: string): string {
    const PATHS = CONFIG.API.PATHS;
    let code: string;

    switch (true) {
      case url.toLowerCase().indexOf(PATHS.GET_VEHICLE.toLowerCase()) > -1:
        code = ENDPOINT_ERROR_CODES.GET_VEHICLE;
        break;
      case url.toLowerCase().indexOf(PATHS.DOCUMENT_TYPES.toLowerCase()) > -1:
        code = ENDPOINT_ERROR_CODES.GET_CONTACT_DOCUMENT_TYPES;
        break;
      case url.toLowerCase().indexOf(PATHS.GET_CONTACT.toLowerCase()) > -1:
        code = ENDPOINT_ERROR_CODES.GET_CONTACT;
        break;
      case url.toLowerCase().indexOf(PATHS.GET_TARIFF.toLowerCase()) > -1:
        code = ENDPOINT_ERROR_CODES.GET_TARIFF;
        break;
      case url.toLowerCase().indexOf(PATHS.CALCULATE_EXIST_IN_INSURANCE_POLICY.toLowerCase()) > -1:
        code = ENDPOINT_ERROR_CODES.CALCULATE_EXIST_IN_INSURANCE_POLICY;
        break;
      case url.toLowerCase().indexOf(PATHS.NEW_INSURANCE_POLICTY_BUDGET.toLowerCase()) > -1:
        code = ENDPOINT_ERROR_CODES.NEW_INSURANCE_POLICTY_BUDGET;
        break;
      case url.toLowerCase().indexOf(PATHS.PORTAL_CONFIGURATIONS.toLowerCase()) > -1:
        code = ENDPOINT_ERROR_CODES.GET_PORTAL_CONFIGURATION;
        break;
      case url.toLowerCase().indexOf(PATHS.SYSTEM_CONFIGURATIONS.toLowerCase()) > -1:
        code = ENDPOINT_ERROR_CODES.GET_PORTAL_SYSTEM_CONFIGURATIONS;
        break;
      case url.toLowerCase().indexOf(PATHS.SAVE_CONTACT_LOG.toLowerCase()) > -1:
        code = ENDPOINT_ERROR_CODES.SAVE_CONTACT_LOG;
        break;
      case url.toLowerCase().indexOf(PATHS.GET_BENEFITS.toLowerCase()) > -1:
        code = ENDPOINT_ERROR_CODES.GET_BENEFITS;
        break;
      case url.toLowerCase().indexOf(PATHS.GET_PERSON.toLowerCase()) > -1:
        code = ENDPOINT_ERROR_CODES.GET_PERSON;
        break;
      case url.toLowerCase().indexOf(PATHS.GET_CITIES.toLowerCase()) > -1:
        code = ENDPOINT_ERROR_CODES.GET_CITIES;
        break;
      case url.toLowerCase().indexOf(PATHS.VALIDATE_IP_INSURANCE_POLICY.toLowerCase()) > -1:
        code = ENDPOINT_ERROR_CODES.VALIDATE_IP_INSURANCE_POLICY;
        break;
      case url.toLowerCase().indexOf(PATHS.VALIDATE_ADDRESS_INSURANCE_POLICY.toLowerCase()) > -1:
        code = ENDPOINT_ERROR_CODES.VALIDATE_ADDRESS_INSURANCE_POLICY;
        break;
      case url.toLowerCase().indexOf(PATHS.VALIDATE_EMAIL_INSURANCE_POLICY.toLowerCase()) > -1:
        code = ENDPOINT_ERROR_CODES.VALIDATE_EMAIL_INSURANCE_POLICY;
        break;
      case url.toLowerCase().indexOf(PATHS.GET_VEHICLE_NATIONAL_OPERATION_CARDS.toLowerCase()) > -1:
        code = ENDPOINT_ERROR_CODES.GET_VEHICLE_NATIONAL_OPERATION_CARDS;
        break;
      case url.toLowerCase().indexOf(PATHS.GET_INVOICE_REGIMEN_TYPES.toLowerCase()) > -1:
        code = ENDPOINT_ERROR_CODES.GET_INVOICE_REGIMEN_TYPES;
        break;
      case url.toLowerCase().indexOf(PATHS.GET_VEHICLE_BRANDS.toLowerCase()) > -1:
        code = ENDPOINT_ERROR_CODES.GET_VEHICLE_BRANDS;
        break;
      case url.toLowerCase().indexOf(PATHS.GET_VEHICLE_BRAND_LINES.toLowerCase()) > -1:
        code = ENDPOINT_ERROR_CODES.GET_VEHICLE_BRAND_LINES;
        break;
      case url.toLowerCase().indexOf(PATHS.GET_RUNT_FUEL_TYPES.toLowerCase()) > -1:
        code = ENDPOINT_ERROR_CODES.GET_RUNT_FUEL_TYPES;
        break;
      case url.toLowerCase().indexOf(PATHS.GET_RUTS.toLowerCase()) > -1:
        code = ENDPOINT_ERROR_CODES.GET_RUTS;
        break;
      case url.toLowerCase().indexOf(PATHS.VEHICLE_CONFIGURATIONS.toLowerCase()) > -1:
        code = ENDPOINT_ERROR_CODES.GET_VEHICLE_CLASSES_WITH_MINISTRIES_AND_SERVICES;
        break;
      case url.toLowerCase().indexOf(PATHS.GET_INSURANCE_POLICY_STATE.toLowerCase()) > -1:
        code = ENDPOINT_ERROR_CODES.GET_INSURANCE_POLICY_STATE;
        break;
      default:
        code = '00';
    }

    return code;
  }
}
