import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-success-click-to-call-modal',
  templateUrl: './success-click-to-call-modal.component.html',
  styleUrls: ['./success-click-to-call-modal.component.scss'],
})
export class SuccessClickToCallModalComponent implements OnInit {

  constructor(
    private _modalController: ModalController,
  ) { }

  ngOnInit() { }

  public async dismiss(): Promise<void> {
    await this._modalController.dismiss();
  }

}
