import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { SuccessClickToCallModalComponent } from './success-click-to-call-modal.component';

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
    ],
    declarations: [SuccessClickToCallModalComponent],
    exports: [SuccessClickToCallModalComponent],
    entryComponents: [SuccessClickToCallModalComponent]
})
export class SuccessClickToCallModalComponentModule { }
