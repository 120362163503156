import { Component, OnInit, Input } from '@angular/core';

import { ModalController } from '@ionic/angular';

import { PortalConfigService } from "../../services/portal-config.service";

@Component({
  selector: 'app-message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss'],
})
export class MessageModalComponent implements OnInit {

  // titulo del modal
  @Input('title') title: string;

  // mensaje del modal
  @Input('message') message: string;

  // tipo de modal: warning, error, info
  @Input('type') type: string;

  // texto del botón
  @Input('buttonMessage') buttonMessage: string;

  public endErrorMessage: string;

  constructor(
    private _modalController: ModalController,
    private portalConfig: PortalConfigService,
  ) {
    this.endErrorMessage = this.portalConfig.getErrorMessages().MessageErrorInvalid;
  }

  ngOnInit() { }

  public close(): void {
    this._modalController.dismiss();
  }

}

// configuraciones básicas que se exportan para no tener que 
// hacerlas a mano. 
export const MODAL_ERROR_BASIC_CONFIG = {
  showBackdrop: true,
  backdropDismiss: false,
  cssClass: 'pop-up-message-modal' // esta clase está configurada en src/theme/se/_modals.scss
};


// interface de configuración
export interface iPopUpMessageComponent {
  title        : string;
  message      : string;
  type         : 'info' | 'warning' | 'error';
  buttonMessage: string;
};

