import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HasVehicleGuard } from './guards/has-vehicle.guard';

const routes: Routes = [
  { path: '', loadChildren: './pages/home/home.module#HomePageModule', pathMatch: 'full' },
  { path: 'completa-los-datos', loadChildren: './pages/completa-los-datos/completa-los-datos.module#CompletaLosDatosPageModule', canActivate: [HasVehicleGuard] },
  { path: 'verifica-los-datos', loadChildren: './pages/verifica-los-datos/verifica-los-datos.module#VerificaLosDatosPageModule', canActivate: [HasVehicleGuard] },
  { path: 'completa-y-verifica', loadChildren: './pages/completa-y-verifica/completa-y-verifica.module#CompletaYVerificaPageModule', canActivate: [HasVehicleGuard] },
  { path: 'retorno-pasarela', loadChildren: './pages/retorno-pasarela/retorno-pasarela.module#RetornoPasarelaPageModule' },
  { path: 'pasarela', loadChildren: './pages/pasarela/pasarela.module#PasarelaPageModule', canActivate: [HasVehicleGuard] },
  { path: 'hash-not-found', loadChildren: './pages/hash-not-found/hash-not-found.module#HashNotFoundPageModule' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
