import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ClickToCallModalComponent } from './click-to-call-modal.component';

// custom modules
import { ErrorFieldComponentModule } from '../error-field/error-field.component.module';

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ErrorFieldComponentModule,
    ],
    declarations: [ClickToCallModalComponent],
    exports: [ClickToCallModalComponent],
    entryComponents: [ClickToCallModalComponent]
})
export class ClickToCallModalComponentModule { }
